import { useQuery } from '@tanstack/vue-query'

import { queryKeys } from '../../queryClient'
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '~/constants'
import localstorage from '~/foundation/storage/localstorage'
import keycloackApi from '~/services-v2/keycloack-api/keycloack-api'
import personApi from '~/services-v2/person-api/person-api'
import KnownError from '~/exceptions/KnownError'
import coreApi from '~/services-v2/core-api/core-api'
import cookie from '~/foundation/storage/cookie'
import * as personUtils from '~/services-v2/person-api/person-api.utils'

export default function useAuth() {
  const queryKey = queryKeys.AUTH.STATUS

  const queryFn = async () => {
    const token = localstorage.get<string>(ACCESS_TOKEN_STORAGE_KEY)
    const refreshToken = localstorage.get<string>(REFRESH_TOKEN_STORAGE_KEY)

    if (!token)
      throw new KnownError('UNAUTHENTICATED')

    const [keycloack, me] = await Promise.all([
      keycloackApi.getUserInfo(),
      coreApi.me(),
    ])
    const person = await personApi.getUser(keycloack.sub)

    const solfacilplus = {
      level: personUtils.getLevel(person),
      currentScore: personUtils.getCurrentScore(person),
      goScore: personUtils.getGoalScore(person),
      nextTransitionDate: personUtils.getNextTrasitionDate(person),
      hasRisk: personUtils.getHasRisk(person),
    }

    // SYNC USER DATA AND COOKIES
    useAppStorage().set('user', me) // TODO: Replace with a proper store
    cookie.set('access_token', token, { expires: 1 / 24 }) // 1 hour
    cookie.set('refresh_token', refreshToken!, { expires: 1 / 24 }) // 1 hour

    return {
      me,
      keycloack,
      person,
      solfacilplus,
    }
  }

  return useQuery({
    queryFn,
    queryKey,
    retry: 2,
    refetchOnWindowFocus: true,
    retryOnMount: true,
    retryDelay: 500,
    refetchInterval: 1000 * 60 * 5, // 5 minute
    staleTime: 1000 * 60 * 5, // 5 minute
  })
}

export type UseAuthData = ReturnType<typeof useAuth>['data']['value']
