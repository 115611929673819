<script setup lang="ts">
import CustomerRegisterService from '~/services/customer-register/CustomerRegister'
import Santander from '~/pages/customer/santander/index.vue'
import Solfacil from '~/pages/customer/solfacil/index.vue'
import { ProviderEnum } from '~/utils/customer-register/CustomerRegister'
import SimulatorService from '~/services/simulator/Simulator'
import InstallationService from '~/services/installation/Installation'
import { useFinancing } from '~/store/useFinancing'
import verify from '~/utils/verify'

const { track } = useMixpanel()

const providersOptions = {
  [ProviderEnum.SANTANDER]: Santander,
  [ProviderEnum.SOLFACIL]: Solfacil,
}

const router = useRouter()
const { isLoading: isFinancingLoading } = useFinancing(router.currentRoute.value.params.id as string)

const simulatorService = new SimulatorService(useApi('simulator'))
const projectPersonType = ref('')
const isOnflexPartner = ref(false)
const loadingGetProject = ref(false)
const customerRegisterService = new CustomerRegisterService(useApi('customerRegister'))
const customerRegisterProvider = ref<ProviderEnum | null>(null)
const financingForm = ref()
const hardwareForm = ref()
const loadingPageCustomer = ref(false)

onMounted(async () => {
  trackEventMixpanel()

  await getProject()
  await Promise.all([
    getCustomerRegistrationForm(),
    fetchHardwareForm(),
  ])
})

async function getProject() {
  try {
    loadingGetProject.value = true
    const { data } = await simulatorService.get_project(router.currentRoute.value.params.id)

    if (data) {
      verify.oldJourneyFinancingListRedirect(data.new_journey || false)
      projectPersonType.value = data?.person_type
      isOnflexPartner.value = data?.is_onflex_partner || false
    }
  }
  catch (e) {
    console.error('File [id].vue > Não foi possível carregar as informações do projeto. Por favor, tente novamente.')
  }
  finally {
    loadingGetProject.value = false
  }
}

async function TriggerRefetch() {
  await getCustomerRegistrationForm()
}

async function getCustomerRegistrationForm() {
  loadingPageCustomer.value = true
  try {
    const response_customer_form = (await customerRegisterService.get_customer_registration_form(String(router.currentRoute.value.params.id), projectPersonType.value)).data

    financingForm.value = response_customer_form

    if (response_customer_form?.provider?.name)
      customerRegisterProvider.value = response_customer_form?.provider?.name as ProviderEnum

    loadingPageCustomer.value = false
  }
  catch {
    loadingPageCustomer.value = false
  }
  finally {
    loadingPageCustomer.value = false
  }
}

async function fetchHardwareForm() {
  try {
    const installationService = new InstallationService(useApi('installation'))
    const hardwareFormResponse = (await installationService.get_hardware_model(String(router.currentRoute.value.params.id)))

    hardwareForm.value = hardwareFormResponse
  }
  catch {}
}

function trackEventMixpanel() {
  track('customer_data_page_view', { trigger: 'Ver a página Dados do cliente' })
}

const showEmptyState = computed(() => {
  return (projectPersonType.value && projectPersonType.value !== 'PF')
    && router.currentRoute.value.fullPath.includes('customer')
    && !customerRegisterProvider.value
})
</script>

<template>
  <div v-if="loadingPageCustomer || loadingGetProject || isFinancingLoading" class="container">
    <div class="my-4 card-container flex flex-col justify-between w-full">
      <span class="loader-project" />
    </div>
  </div>
  <div v-else>
    <div v-if="projectPersonType && projectPersonType === 'PF' && customerRegisterProvider">
      <h1>
        <component
          :is="providersOptions[customerRegisterProvider]"
          :person-form="financingForm.person_form"
          :plant-address="financingForm.plant_address_form"
          :residential-address="financingForm.residential_address_form"
          :installation-document="financingForm.installation_document"
          :provider="customerRegisterProvider"
          :status="financingForm.status"
          :trigger-refetch="TriggerRefetch"
          :is-onflex-partner="isOnflexPartner"
          :hardware-form="{ response: hardwareForm, refetch: fetchHardwareForm }"
        />
      </h1>
    </div>
    <div
      v-if="showEmptyState"
      class="container"
    >
      <div class="bg-neutral-high-pure w-full rounded-lg p-10 flex items-center justify-center">
        <SolEmptyState
          id="emptyStateCustomer"
          :title="$t('emptyStateCustomer.title')"
          :subtitle="$t('emptyStateCustomer.subtitle')"
          variant="no-content"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container {
  @apply flex flex-col items-center px-4xs pb-6 mt-6;
  @screen md:system {
    @apply p-12 mt-0;
  }
}

.loader-project {
  @apply h-96 rounded-lg;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9)
    url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
    center center;
}
</style>

<route lang="yaml">
meta:
  layout: client-data
</route>
