<script setup lang="ts">
import { useField, useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useDownload, useToast } from '@solfacil/girassol'
import ReceiptModelService from '~/services/receipt-model/ReceiptModel'
import type { Invoice, InvoicesKindEnum, ReceiptModel } from '~/utils/receipt-model/ReceiptModel'
import { useFinancing } from '~/store/useFinancing'

const props = defineProps<{
  data: ReceiptModel
  invoiceSeleted: string
  submit: boolean
  combo: boolean
}>()
const emit = defineEmits(['readyToSubmit', 'updateErrors'])
interface ResponseUpload {
  invoice_id: string
  public_id: string
  filename: string
  mimetype: string
  size: number
  url: string
  status: string
}
const { downloadFile } = useDownload()

const receiptModelService = new ReceiptModelService(useApi('receiptModel'))
const router = useRouter()
const { financing } = useFinancing(router.currentRoute.value.params.id as string)
const globalStatusIsCanceled = financing?.status === 'canceled'
const { t } = useI18n()
const { createErrorToast } = useToast()
const hasCommission = props.data.roles.invoices.commission
const loadingStates = ref({
  commission: false,
  products: false,
  service_product: false,
})
const responsePostUpload = reactive({
  commission: [] as ResponseUpload[],
  products: [] as ResponseUpload[],
  service_product: [] as ResponseUpload[],
})

const initialValues = {
  service: null,
  commission: null,
  products: null,
  service_product: null,
}

const validationSchema = toTypedSchema(
  zod.object({
    service: zod.any().optional(),
    service_product: zod.any().optional(),
    commission: zod.any().optional(),
    products: zod.any().optional(),
  }),
)

const { validate, errors, setFieldValue, setFieldError } = useForm({
  validationSchema,
  initialValues,
})

const hasInvoice = reactive({
  commission: ref(props.data.invoice.filter((invoice: any) => invoice.invoice_kind.includes('commission'))),
  products: ref(props.data.invoice.filter((invoice: any) => invoice.invoice_kind.includes('products'))),
  service_product: ref(props.data.invoice.filter((invoice: any) => invoice.invoice_kind.includes('service_product'))),
})

const createFormData = (invoice, type) => {
  const formData = new FormData()
  formData.append('project_id', String(router.currentRoute.value.params.id))
  formData.append('kind', type)
  formData.append('file', invoice.files[0], invoice.files[0].name)
  return formData
}

const uploadInvoice = async (formData, type) => {
  loadingStates.value[type] = true
  try {
    const response = await receiptModelService.post_receipt_model_upload(formData)
    if (response)
      responsePostUpload[type].push(response)
  }
  catch (error) {
    createErrorToast(t('receiptModel.alert.upload_not_sent'))
  }
  finally {
    loadingStates.value[type] = false
  }
}

async function handleFormChange(event: any, type: InvoicesKindEnum) {
  const { valid } = await validate()
  if (!valid)
    return
  const emitInvoicesToUpload: FormData[] = []
  const formData = createFormData(event.target, type)
  emitInvoicesToUpload.push(formData)
  await uploadInvoice(formData, type)
  emit('readyToSubmit', emitInvoicesToUpload)
}

watch(errors, (newErrors) => {
  emit('updateErrors', newErrors)
})

const deleteByResponse = async (resp: object, invoice: string) => {
  loadingStates.value[invoice] = true
  try {
    const response = await receiptModelService.delete_receipt_model_upload(resp[0].invoice_id)
    if (response) {
      responsePostUpload[invoice] = []
      setFieldValue(invoice as InvoicesKindEnum, null)
    }
  }
  catch (error) {
    createErrorToast('Arquivo não deletado, tente novamente!')
  }
  finally {
    loadingStates.value[invoice] = false
  }
}

const deleteById = async (resp: string, invoice: string) => {
  loadingStates.value[invoice] = true
  try {
    const response = await receiptModelService.delete_receipt_model_upload(resp)
    if (response) {
      await receiptModelService.get_receipt_model(String(router.currentRoute.value.params.id))
      hasInvoice[invoice] = []
      setFieldValue(invoice as InvoicesKindEnum, null)
    }
  }
  catch (error) {
    createErrorToast('Arquivo não deletado, tente novamente!')
  }
  finally {
    loadingStates.value[invoice] = false
  }
}

const disableField = computed(() => {
  return globalStatusIsCanceled || Object.values(loadingStates.value).some(state => state)
})

const fileNameProducts = computed(() => {
  return responsePostUpload?.products.length > 0 ? responsePostUpload.products[0].filename : ''
})

const fileNameCommission = computed(() => {
  return responsePostUpload?.commission.length > 0 ? responsePostUpload.commission[0].filename : ''
})
const fileNameServiceProduct = computed(() => {
  return responsePostUpload?.service_product.length > 0 ? responsePostUpload.service_product[0].filename : ''
})
const urlProducts = computed(() => {
  return responsePostUpload?.products.length > 0 ? responsePostUpload.products[0].url : ''
})

const urlCommission = computed(() => {
  return responsePostUpload.commission.length > 0 ? responsePostUpload.commission[0].url : ''
})

const urlServiceProduct = computed(() => {
  return responsePostUpload.service_product.length > 0 ? responsePostUpload.service_product[0].url : ''
})

const separateInvoices = computed(() => {
  return !props.combo && props.invoiceSeleted === 'separate_invoices'
})

const singleInvoice = computed(() => {
  return !props.combo && props.invoiceSeleted === 'single_invoice'
})

const showWhenNotServiceInvoice = computed(() => {
  return ((hasCommission || separateInvoices)) || (hasCommission && separateInvoices)
})

function getFileTypeIcon(filetype: string) {
  return filetype.split('.')[1]
}

function getDocumentInfoByStatus(invoice: Invoice) {
  return {
    'file-type-icon': [getFileTypeIcon(invoice.document_filename ?? '')],
    'file-name': [invoice.document_filename],
    'status-type-document': invoice.invoice_status === 'awaiting_analysis' ? 'pending' : invoice.invoice_status,
  }
}

function showMessageStatusComponent(invoice: Invoice) {
  const isAwaitingAnalysis = ['awaiting_analysis'].includes(invoice.invoice_status) && ['in_analysis', 'pending_change'].includes(props.data.status)
  const isApproved = invoice.invoice_status === 'approved'

  return (isAwaitingAnalysis || isApproved)
}

function findDeniedInvoices() {
  const isDenied = (invoice: Invoice) => invoice.invoice_status === 'denied'

  const deniedInvoices: Invoice[] = Object.values(hasInvoice)
    .flatMap(invoices => invoices)
    .filter(isDenied)

  deniedInvoices.forEach((invoice) => {
    setFieldError(invoice.invoice_kind, invoice.invoice_reason)
  })
}

onMounted(() => {
  if (props.data.status === 'pending_change')
    findDeniedInvoices()
})
</script>

<template>
  <template v-if="showWhenNotServiceInvoice">
    <div class="title mt-4">
      <span class="font-highlight  text-neutral-low-pure text-[16px] md:system:text-[20px]">
        {{ t('customer.invoice', { count: hasCommission && separateInvoices ? 1 : 0 }) }}
      </span>
    </div>
    <div class="leading-3 pb-4xs">
      <span class="fonts-body-small-regular md:system:fonts-body-medium-regular">
        {{ t('customer.invoice_subtitle', { count: hasCommission && separateInvoices ? 1 : 0 }) }}
      </span>
    </div>
  </template>
  <div>
    <form class="grid grid-cols-1 md:site:grid-cols-2 gap-4 md:site:gap-8">
      <template v-if="separateInvoices">
        <template v-if="hasInvoice.products.length">
          <template v-for="invoice in hasInvoice.products" :key="invoice.invoice_id">
            <div v-if="showMessageStatusComponent(invoice)">
              <span class="font-bold text-3xs text-neutral-low-dark">{{ t('form.hardware_invoice') }}</span>
              <SharedMessageStatus
                v-bind="getDocumentInfoByStatus(invoice)"
              />
            </div>

            <SolFileUpload
              v-else
              id="products"
              name="products"
              :file-name="invoice.invoice_status === 'denied' ? fileNameProducts : invoice.document_filename"
              :download-src="invoice.document_url"
              :use-field="useField"
              :download-menu="['download', 'delete']"
              accept=".pdf"
              :multiple="false"
              :loading="loadingStates.products"
              :placeholder="t('formDocument.placeholder')"
              :label="t('form.hardware_invoice')"
              :class="[{ 'pointer-events-none opacity-60': disableField }, { 'error-upload': invoice.invoice_status === 'denied' }]"
              @change="handleFormChange($event, 'products')"
              @menu:download="url => downloadFile(invoice.invoice_status === 'denied' ? urlProducts : invoice.document_url || '', invoice.invoice_status === 'denied' ? fileNameProducts : invoice.document_filename)"
              @menu:delete="deleteById(invoice.invoice_id, 'products')"
            />
          </template>
        </template>

        <template v-else>
          <SolFileUpload
            id="products"
            name="products"
            :file-name="fileNameProducts"
            :download-src="urlProducts"
            :use-field="useField"
            :download-menu="['download', 'delete']"
            accept=".pdf"
            :multiple="false"
            :loading="loadingStates.products"
            :placeholder="t('formDocument.placeholder')"
            :label="t('form.hardware_invoice')"
            :class="`${disableField ? 'pointer-events-none opacity-60' : ''}`"
            @change="handleFormChange($event, 'products')"
            @menu:download="url => downloadFile(urlProducts, fileNameProducts)"
            @menu:delete="deleteByResponse(responsePostUpload.products, 'products')"
          />
        </template>
      </template>

      <template v-if="singleInvoice">
        <template v-if="hasInvoice.service_product.length">
          <template v-for="invoice in hasInvoice.service_product" :key="invoice.invoice_id">
            <div v-if="showMessageStatusComponent(invoice)">
              <span class="font-bold text-3xs text-neutral-low-dark">{{ t('form.service_invoice') }}</span>
              <SharedMessageStatus
                v-bind="getDocumentInfoByStatus(invoice)"
              />
            </div>

            <SolFileUpload
              v-else
              id="service_product"
              name="service_product"
              :file-name="invoice.invoice_status === 'denied' ? fileNameServiceProduct : invoice.document_filename"
              :download-src="invoice.document_url"
              :use-field="useField"
              :download-menu="['download', 'delete']"
              accept=".pdf"
              :loading="loadingStates.service_product"
              :multiple="false"
              :placeholder="t('formDocument.placeholder')"
              :label="t('form.service_invoice')"
              :class="[{ 'pointer-events-none opacity-60': disableField }, { 'error-upload': invoice.invoice_status === 'denied' }]"
              @change="handleFormChange($event, 'service_product')"
              @menu:download="url => downloadFile(invoice.invoice_status === 'denied' ? urlServiceProduct : invoice.document_url || '', invoice.invoice_status === 'denied' ? fileNameServiceProduct : invoice.document_filename)"
              @menu:delete="deleteById(invoice.invoice_id, 'service_product')"
            />
          </template>
        </template>

        <template v-else>
          <SolFileUpload
            id="service_product"
            name="service_product"
            :file-name="fileNameServiceProduct"
            :download-src="urlServiceProduct"
            :use-field="useField"
            :download-menu="['download', 'delete']"
            accept=".pdf"
            :multiple="false"
            :loading="loadingStates.service_product"
            :placeholder="t('formDocument.placeholder')"
            :label="t('form.service_invoice')"
            :class="`${disableField ? 'pointer-events-none opacity-60' : ''}`"
            @change="handleFormChange($event, 'service_product')"
            @menu:download="url => downloadFile(urlServiceProduct, fileNameServiceProduct)"
            @menu:delete="deleteByResponse(responsePostUpload.service_product, 'service_product')"
          />
        </template>
      </template>

      <template v-if="hasCommission">
        <template v-if="hasInvoice.commission.length">
          <template v-for="invoice in hasInvoice.commission" :key="invoice.invoice_id">
            <div v-if="showMessageStatusComponent(invoice)">
              <span class="font-bold text-3xs text-neutral-low-dark">{{ t('form.commission_invoice') }}</span>
              <SharedMessageStatus
                v-bind="getDocumentInfoByStatus(invoice)"
              />
            </div>

            <SolFileUpload
              v-else
              id="commission"
              name="commission"
              :file-name="invoice.invoice_status === 'denied' ? fileNameCommission : invoice.document_filename"
              :download-src="invoice.document_url"
              :use-field="useField"
              :download-menu="['download', 'delete']"
              accept=".pdf"
              :loading="loadingStates.commission"
              :multiple="false"
              :placeholder="t('formDocument.placeholder')"
              :label="t('form.commission_invoice')"
              :class="[{ 'pointer-events-none opacity-60': disableField }, { 'error-upload': invoice.invoice_status === 'denied' }]"
              @change="handleFormChange($event, 'commission')"
              @menu:download="url => downloadFile(invoice.invoice_status === 'denied' ? urlCommission : invoice.document_url || '', invoice.invoice_status === 'denied' ? fileNameCommission : invoice.document_filename)"
              @menu:delete="deleteById(invoice.invoice_id, 'commission')"
            />
          </template>
        </template>

        <template v-else>
          <SolFileUpload
            id="commission"
            name="commission"
            :file-name="fileNameCommission"
            :download-src="urlCommission"
            :use-field="useField"
            :download-menu="['download', 'delete']"
            accept=".pdf"
            :loading="loadingStates.commission"
            :multiple="false"
            :placeholder="t('formDocument.placeholder')"
            :label="t('form.commission_invoice')"
            :class="`${disableField ? 'pointer-events-none opacity-60' : ''}`"
            @change="handleFormChange($event, 'commission')"
            @menu:download="url => downloadFile(urlCommission, fileNameCommission)"
            @menu:delete="deleteByResponse(responsePostUpload.commission, 'commission')"
          />
        </template>
      </template>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.receipt-model .method_receipt {
  @apply my-2xs p-4xs;
  @apply border-a-sm border-neutral-low-light rounded-md;

  md:system {
    @apply my-xs p-2xs;
  }
}

.receipt-model .title {
  @apply pb-2;
}

.receipt-model .title h4 {
  @apply fonts-heading-h4;
}

.error-upload {
  :deep(.sol-download) {
    @apply border-1 border-feedback-negative-pure;
  }
}
</style>
