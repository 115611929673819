import dayjs from 'dayjs'
import format from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ptBR from 'dayjs/locale/pt-br'

const date = dayjs

date.extend(format)
date.extend(utc)
date.extend(timezone)
date.locale(ptBR)

date.tz.setDefault('America/Sao_Paulo')

export default date
