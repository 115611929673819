import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://stg-payment-bff.solfacil.com.br',
  },
  prod: {
    url: 'https://payment-bff.solfacil.com.br',
  },
  stg: {
    url: 'https://stg-payment-bff.solfacil.com.br',
  },
} as RestConnections
