<script setup lang="ts">
import { SolMenu } from '@solfacil/girassol'
import useAuth from '~/store-v2/auth/useAuth'
import useLogout from '~/store-v2/auth/useLogout'

const {
  data: auth,
} = useAuth()

const {
  mutateAsync: logout,
  isPending: isLogoutPending,
} = useLogout()
</script>

<template>
  <header class="header">
    <div class="profile">
      <SolMenu id="header-menu" position="bottom" class="menu">
        <template #label>
          <span class="user-photo">{{ auth?.person.fullname }}</span>
          <div class="user-welcome">
            <span class="title">{{ $t('header.welcome') }}</span>
            <div class="username">
              {{ auth?.person.fullname }}
            </div>
          </div>

          <i-material-symbols-expand-more-rounded />
        </template>

        <SolMenuItem id="logout" class="menu-item" @clicked="logout">
          {{ $t('header.exit') }}
        </SolMenuItem>
      </SolMenu>
    </div>
    <!-- <AppIconHamburger
      v-if="!isSidebarOpen"
      :is-sidebar-open="isSidebarOpen"
      @click="$emit('open')"
    /> -->
  </header>
</template>

<style lang="scss" scoped>
.header {
  @apply hidden;
  @apply bg-neutral-high-pure;
  @apply w-full h-24.5 px-2xs py-xs;
  @apply border-b-1 border-neutral-high-medium bg-opacity-semiopaque;
  @screen lg:system {
    @apply top-auto;
    @apply px-2xs;
  }
  @screen md:system {
    @apply flex flex-row-reverse items-center justify-between;
  }
  .profile {
    @apply flex items-center;
    > .sol-menu-core > :deep(.menu) {
      @apply right-2;
    }
    > .menu :deep(.toggle-menu) {
      @apply flex items-center;
      > .user-photo {
        @apply grid place-items-center;
        @apply w-12 h-12 rounded-half;
        @apply fonts-body-x-large-regular uppercase;
        @apply bg-brand-secondary-light text-brand-secondary-dark;
        @apply mr-nano;
      }
      > .user-welcome {
        @apply place-items-center;
        @apply text-left;
        @apply mr-md;
        > .title {
          @apply font-base font-regular text-micro;
          @apply text-neutral-low-light;
        }
        > .username {
          @apply font-base font-bold text-2xs capitalize;
          @apply text-neutral-low-dark;
        }
      }
    }
  }
}
</style>
