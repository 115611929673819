import { useMutation } from '@tanstack/vue-query'

import queryClient, { queryKeys } from '../../queryClient'
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '~/constants'
import storage from '~/foundation/storage/localstorage'
import keycloackApi from '~/services-v2/keycloack-api/keycloack-api'
import cookie from '~/foundation/storage/cookie'

export default function useLogin() {
  const mutationKey = queryKeys.AUTH.LOGIN

  function mutationFn(code: string) {
    return keycloackApi.getAccessToken(code)
  }

  return useMutation({
    mutationFn,
    mutationKey,
    onSuccess(data) {
      storage.set(ACCESS_TOKEN_STORAGE_KEY, data.access_token)
      storage.set(REFRESH_TOKEN_STORAGE_KEY, data.refresh_token)
      cookie.set('access_token', data.access_token, { expires: data.expires_in })
      cookie.set('refresh_token', data.refresh_token, { expires: data.refresh_expires_in })
      queryClient.refetchQueries({ queryKey: queryKeys.AUTH.STATUS })
    },
    retry: 3,
    retryDelay: 2000,
  })
}
