<script lang="ts" setup>
import type { Data } from '@solfacil/girassol/dist/types/components/list/types'
import type { Type } from '@solfacil/girassol/dist/types/components/tag/types'
import { P, match } from 'ts-pattern'
import { useToast } from '@solfacil/girassol'
import { Journey } from '~/types/enum'
import type { User } from '~/utils/auth/User'
import { conventionalNameFormat } from '~/utils/formatter/name'

import IconPlus from '~icons/material-symbols/add'
import IconSchedule from '~icons/material-symbols/schedule-outline'
import IconCheck from '~icons/material-symbols/check-circle-outline'
import IconWarning from '~icons/material-symbols/warning-outline'
import IconOpenInNew from '~icons/material-symbols/open-in-new'

import FinancingFlowService from '~/services/financingFlow/FinancingFlow'
import type { FinancingList, FinancingListItem, FinancingListParams, OptionsFilters, SelectedFilters } from '~/utils/financing-flow/Financing'

const optionsFiltersList = ref({} as OptionsFilters)

const config = import.meta.env

const { track } = useMixpanel()
const router = useRouter()
const { t } = useI18n()
const toast = useToast()
const user: User = useAppStorage().get('user')

const financingFlowService = new FinancingFlowService(useApi('financingFlow'))

const FilterStatus = {
  approved: t('financing.list.status.approved'),
  canceled: t('financing.list.status.canceled'),
  concluded: t('financing.list.status.concluded'),
  expired: t('financing.list.status.expired'),
  kit_pending: t('financing.list.status.kit_pending'),
  pendency: t('financing.list.status.pendency'),
  pending: t('financing.list.status.pending'),
  pending_customer: t('financing.list.status.pending_customer'),
  pending_receipt: t('financing.list.status.pending_receipt'),
  reproved: t('financing.list.status.reproved'),
  send_contract: t('financing.list.status.send_contract'),
  under_analysis: t('financing.list.status.under_analysis'),
}

const FilterSteps = {
  formalization: t('financing.list.step.formalization'),
  installation: t('financing.list.step.installation'),
  simulation: t('financing.list.step.simulation'),
}

const sortByOptions = [
  { name: t('financing.sort_by.updated_at_desc'), value: 'updated_at:desc' },
  { name: t('financing.sort_by.created_at_desc'), value: 'created_at:desc' },
  { name: t('financing.sort_by.created_at_asc'), value: 'created_at:asc' },
  { name: t('financing.sort_by.expires_at_asc'), value: 'expires_at:asc' },
]

const flagNewJourney = ref()
const page = ref(1)
const query = ref<string>()
const filtersFinancingValue = ref<SelectedFilters>()
const financingList = ref<FinancingList>()
const loading = ref(false)
const sortBy = ref(sortByOptions[0])
const isEnabledToBNPL = ref(false)

function newSimulation() {
  track('financing-list_button_nova-simulacao', { trigger: 'Clique no botão de nova simulação na listagem de financiamentos' })
  router.push('/simulation/new')
}

function goCombo() {
  track('financing-list_banner_saiba-mais', { trigger: 'Clique no botão de saiba mais que tem no banner referente ao Combo Fácil na listagem de financiamentos' })
  const url = 'https://combofacil.solfacil.com.br/'
  window.open(url, '_blank')
}

function openOldList() {
  window.location.href = `${config.VITE_URL_LEGACY}/financiamentos`
}

function openSimulationList() {
  router.push('/simulation/list')
}

const isLargeScreen = useMediaQuery('(min-width: 1024px)')

const stepsToShow = ['formalization', 'installation']

async function fetchFinancingList() {
  try {
    loading.value = true

    const params: FinancingListParams = {
      page: page.value,
      page_size: 10,
      order_by: sortBy.value.value,
      steps: filtersFinancingValue?.value?.stepsField?.value ? filtersFinancingValue.value.stepsField.value : stepsToShow,
    }

    if (query.value)
      params.query = query.value

    if (filtersFinancingValue?.value?.statusField?.value)
      params.status = filtersFinancingValue.value.statusField.value

    financingList.value = await financingFlowService.getFinancingList({
      ...params,
    })

    if (financingList.value) {
      if (financingList.value?.total_by_status && financingList.value?.total_by_step) {
        const filterStatus = await Object.keys(financingList.value?.total_by_status).map(key => ({
          name: `${FilterStatus[key]} (${financingList.value?.total_by_status[key]})`,
          value: key,
        }))

        const filterSteps = await Object.keys(financingList.value?.total_by_step).map(key => ({
          name: `${FilterSteps[key]} (${financingList.value?.total_by_step[key]})`,
          value: key,
        }))

        optionsFiltersList.value = {
          title: t('financing.filters.title'),
          filters: [
            {
              id: 'steps-field',
              class: 'w-full md:system:w-[160px]',
              placeholder: t('financing.filters.steps'),
              size: 'small',
              name: 'stepsField',
              label: '',
              options: filterSteps,
            },
            {
              id: 'status-field',
              class: 'w-full md:system:w-[160px]',
              placeholder: t('financing.filters.status'),
              size: 'small',
              name: 'statusField',
              label: '',
              options: filterStatus,
            },
          ],
        }
      }
    }
  }
  catch (error) {
    toast.createErrorToast({
      title: t('financing.list.error.fetching.title'),
      description: t('financing.list.error.fetching.description'),
    })
  }
  finally {
    loading.value = false
  }
}

function getDifferenceInDays(start: Date, end: Date) {
  const diff = start.getTime() - end.getTime()

  return Math.ceil(diff / (1000 * 60 * 60 * 24))
}

function resolveDaysReference(item: FinancingListItem): string {
  const value = match(item)
    .with({ status: P.union('reproved', 'canceled') }, () => '-')
    .with({ step: 'formalization', expires_at: P.nonNullable }, ({ expires_at }) => {
      const expiresAt = new Date(expires_at)

      return getDifferenceInDays(expiresAt, new Date())
    })
    .with({ step: 'simulation', expires_at: P.nonNullable }, ({ expires_at }) => {
      const expiresAt = new Date(expires_at)

      return getDifferenceInDays(expiresAt, new Date())
    })
    .with({ step: 'installation', status: 'concluded', formalized_at: P.nonNullable, installed_at: P.nonNullable }, ({ formalized_at, installed_at }) => {
      const formalizedAt = new Date(formalized_at)
      const installedAt = new Date(installed_at)

      return getDifferenceInDays(installedAt, formalizedAt)
    })
    .with({ step: 'installation', status: P.union('pending', 'under_analysis', 'pendency'), formalized_at: P.nonNullable }, ({ formalized_at }) => {
      const formalizedAt = new Date(formalized_at)

      return getDifferenceInDays(new Date(), formalizedAt)
    })
    .otherwise(() => 0)

  if (typeof value === 'string')
    return value

  return value <= 0 ? '-' : String(value)
}

function resolveLabelReference(item: FinancingListItem) {
  return match(item)
    .with({ step: 'installation', status: P.union('pending', 'under_analysis', 'pendency') }, () => t('financing.list.headers.installing_days'))
    .with({ step: 'installation', status: P.union('concluded', 'canceled') }, () => t('financing.list.headers.installed_days'))
    .with({ step: P.union('formalization', 'simulation') }, () => t('financing.list.headers.expires_at'))
    .otherwise(() => '-')
}

function getListData() {
  return financingList.value?.data.map((item) => {
    const days = resolveDaysReference(item)

    return {
      ...item,
      days_info: days,
    }
  }) as Data[]
}

const financingListProps = {
  headers: {
    id: {
      text: t('financing.list.headers.id'),
      width: '72px',
    },
    customer_name: {
      text: t('financing.list.headers.customer_name'),
    },
    financed_value: {
      text: t('financing.list.headers.financed_value'),
      width: '118px',
    },
    days_info: {
      width: '80px',
    },
    step: {
      text: t('financing.list.headers.step'),
      width: '90px',
    },
    status: {
      width: '140px',
      hide: true,
    },
  },
  sortPositions: {
    mobile: [
      ['customer_name'],
      ['id', 'financed_value', 'days_info'],
      ['step', 'status'],
    ],
    desktop: [
      'customer_name',
      'id',
      'financed_value',
      'days_info',
      'step',
      'status',
    ],
  },
}

function renderStatus(status: string): Type {
  return match(status)
    .with('concluded', () => 'positive')
    .with(P.union('pending', 'pending_customer', 'send_contract', 'pending_receipt', 'pendency', 'kit_pending'), () => 'warning')
    .with('under_analysis', () => 'informative')
    .with(P.union('canceled', 'reproved', 'expired'), () => 'negative')
    .otherwise(() => 'informative') as Type
}

function formatCurrency(value: number) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

function getAnchorByStatus(item: Data) {
  return match(item)
    .with({ status: 'pending_receipt' }, () => 'receipt-model')
    .with({ status: 'pending_customer' }, () => 'biometry')
    .with({ status: 'send_contract' }, () => 'biometry')
    .with({ status: 'kit_pending' }, () => 'accordion_hardware')
    .otherwise(() => '')
}

function goToStepScreen(item: Data) {
  track('simulation-list_list_lista-financiamentos', { trigger: 'Clique em um item de lista, na lista de financiamentos' })

  if (item.status === 'expired')
    return

  match(item)
    .with({ step: 'formalization' }, () => {
      return router.push(`/${item.person_type === 'PJ' ? 'company' : 'customer'}/${item.project_id}#${getAnchorByStatus(item)}`)
    })
    .with({ step: 'simulation' }, () => router.push(`/simulation/result/${item.project_id}`))
    .with({ step: 'installation' }, () => router.push(`/installation/${item.project_id}`))
    .otherwise(() => {})
}

watch(sortBy, () => {
  page.value = 1
  fetchFinancingList()
})

async function setFeatureFlags() {
  flagNewJourney.value = await useFlag('new_journey', { partner_id: user?.parceiro?.id })
  isEnabledToBNPL.value = await useFlag<boolean>('bnpl', { partner_id: user?.parceiro?.id })
}

function setPage(value: any) {
  page.value = value

  fetchFinancingList()
}

const showAlertOldList = computed(() => {
  return Journey.MIXED === flagNewJourney.value
})

watch(query, () => {
  page.value = 1
  fetchFinancingList()
})

function applyFilters(value: SelectedFilters) {
  filtersFinancingValue.value = value
  page.value = 1
  fetchFinancingList()
}

onMounted(() => {
  fetchFinancingList()
  track('financing-list_page-view', { trigger: 'Ver página da financiamentos de simulações' })
  setFeatureFlags()
})
</script>

<template>
  <div class="container-list-financing">
    <div class="page-heading">
      <div>
        <h2 class="fonts-heading-h2">
          {{ t('financing.title') }}
        </h2>
        <p class="fonts-subtitle-small text-neutral-low-light">
          {{ t('financing.subtitle') }}
        </p>
      </div>
      <SolButton id="new-simulation" @click="newSimulation">
        <template #icon-left>
          <IconPlus />
        </template>
        {{ t('simulation.new') }}
      </SolButton>
    </div>
    <section class="banner">
      <div class="flex justify-between align-baseline md:site:align-middle">
        <img src="/images/combo_logo.svg" class="w-33">
        <img src="/images/combo_percent.svg">
      </div>
      <div class="flex flex-col self-center mt-micro">
        <h1 class="fonts-heading-h4 md:site:fonts-heading-h3 text-neutral-high-pure">
          {{ t('financing.banner.title') }}
        </h1>
        <p class="fonts-body-small-regular my-quark md:site:fonts-body-large-regular text-neutral-high-pure">
          {{ t('financing.banner.subtitle') }}
        </p>
        <SolButton id="secondary-large" variant="secondary" on-color class="link-banner" size="small" @click="goCombo">
          <span class="text-neutral-high-pure justify-end">
            {{ t('financing.button.know_more') }}
          </span>
        </SolButton>
      </div>
    </section>

    <section v-if="showAlertOldList">
      <SolAlert
        id="informative-feedback-receipt-model"
        class="mt-0 md:site:mt-8"
        :title="t('financing.alert.title')"
        feedback="informative"
        action="Ver lista antiga"
        @click="openOldList"
      >
        <span>{{ t('financing.alert.subtitle.start') }} <a title="Ver lista antiga" class="underline cursor-pointer" @click="openSimulationList">{{ t('financing.alert.subtitle.link') }}</a>{{ t('financing.alert.subtitle.end') }}</span>
      </SolAlert>
    </section>

    <section
      v-if="(financingList?.total ?? 0) > 0 || Boolean(query)"
      class="box-search p-6 rounded-lg bg-neutral-high-light flex flex-col md:system:flex-row gap-3"
    >
      <FinancingSearch @search-financing-value="(searchFinancingValue) => { query = searchFinancingValue; }" />
      <SharedFilters
        :options-filters-list="optionsFiltersList"
        @send-filters="applyFilters"
      />
    </section>

    <div v-if="loading" class="loading">
      <Loading />
    </div>

    <section v-if="financingList?.total === 0 && !loading" class="empty-state">
      <SolEmptyState
        v-if="(financingList?.total ?? 0) === 0 && !Boolean(query)"
        id="empty-list"
        :title=" t('financing.emptyList.title')"
        :subtitle="t('financing.emptyList.subtitle')"
        variant="empty-list"
      >
        <template #action>
          <div class="flex gap-2 inline-flex show-md">
            <SolButton
              id="btn-list-simulations"
              variant="secondary"
              :size="isLargeScreen ? 'medium' : 'small' "
              @click="openSimulationList"
            >
              {{ t('financing.emptyList.button_list') }}
            </SolButton>
            <SolButton id="btn-new-simulation" :size="isLargeScreen ? 'medium' : 'small'" @click="newSimulation">
              <template #icon-left>
                <IconPlus />
              </template>
              {{ t('simulation.new') }}
            </SolButton>
          </div>
        </template>
      </SolEmptyState>

      <SolEmptyState
        v-else
        id="project-not-found"
        :title=" t('financing.notFound.title')"
        :subtitle="t('financing.notFound.subtitle')"
        variant="no-content"
      >
        <template #action>
          <div class="flex gap-2 inline-flex show-md">
            <SolButton
              id="btn-new-simulation"
              :size="isLargeScreen ? 'medium' : 'small' "
              @click="openOldList"
            >
              <template #icon-right>
                <IconOpenInNew />
              </template>
              {{ t('financing.alert.action') }}
            </SolButton>
          </div>
        </template>
      </SolEmptyState>
    </section>

    <div v-if="(financingList?.total ?? 0) > 0 && !loading" class="financing-list">
      <div class="financing-list-header">
        <p class="mb-2 fonts-body-medium-bold text-neutral-low-medium">
          {{ t('financing.list.results_found', { count: financingList?.total ?? 0 }) }}
        </p>
        <div class="flex">
          <h2 class="text-brand-primary-pure font-bold self-center mr-micro">
            {{ t('financing.sort_by.label') }}
          </h2>
          <div class="w-56">
            <SolSelect
              id="financing-order-by"
              v-model:selected="sortBy"
              name="financing-order-by"
              size="small"
              :options="sortByOptions"
            >
              <template #no-data>
                <h2 class="text-feedback-negative-pure font-bold">
                  {{ t('financing.no_data') }}
                </h2>
              </template>
            </SolSelect>
          </div>
        </div>
      </div>

      <SharedMessageInstallmentsProjects v-if="isEnabledToBNPL" event-mixpanel="financing-list_button_ver-parcelas" />

      <SolList
        v-if="financingList?.data"
        id="financing-list"
        v-bind="financingListProps"
        :hide-menu="true"
        :data="getListData()"
        @listitem:click="goToStepScreen"
      >
        <template #column:customer_name="{ data }">
          <div class="items-center">
            <p class="fonts-body-small-regular text-neutral-low-light">
              {{ t('financing.list.headers.customer_name') }}
            </p>
            <p class="fonts-body-medium-regular">
              {{ conventionalNameFormat(String(data.value)) }}
            </p>
          </div>
        </template>
        <template #column:days_info="{ data, index }">
          <div class="items-center">
            <p class="fonts-body-small-regular text-neutral-low-light">
              {{ financingList.data[index].status !== 'concluded' ? resolveLabelReference(financingList.data[index]) : '' }}
            </p>
            <p class="fonts-body-medium-regular flex gap-1">
              <IconSchedule v-if="data.value !== '-' && financingList.data[index].status !== 'concluded' && parseInt(data.value as string) > 10" />
              <IconCheck v-else-if="financingList.data[index].status === 'concluded'" class="text-brand-secondary-pure" />
              <IconWarning v-else-if="data.value !== '-' && parseInt(data.value as string) < 10" class="text-feedback-negative-pure" />
              {{ (financingList.data[index].status !== 'concluded') ? t('financing.list.days_info', { count: data.value !== '-' ? parseInt(data.value as string) : 0 }) : '' }}
            </p>
          </div>
        </template>

        <template #column:financed_value="{ data, index }">
          <div class="items-center">
            <p class="fonts-body-small-regular text-neutral-low-light">
              {{ t('financing.list.headers.financed_value') }}
            </p>
            <p class="fonts-body-medium-regular">
              {{ typeof data.value === 'number' ? formatCurrency(data.value) : '-' }}
              <SolTag
                v-if="financingList.data[index].percentage_paid"
                :id="`financing-paid-${financingList.data[index].id}`"
                :type="financingList.data[index].percentage_paid === 100 ? 'positive' : 'informative'" size="small"
              >
                {{ t('financing.list.tags.paid_percentage', { percentage: financingList.data[index].percentage_paid }) }}
              </SolTag>
            </p>
          </div>
        </template>

        <template #value:status="{ data }">
          <SolTag
            :id="`financing-status-${data.value}`"
            class="lg:site:w-full"
            :type="renderStatus(String(data.value))"
          >
            {{ t(`financing.list.status.${data.value}`) }}
          </SolTag>
        </template>

        <template #value:step="{ data }">
          {{ t(`financing.list.step.${data.value}`) }}
        </template>
      </SolList>

      <SolPagination
        id="test"
        v-model:current="page"
        :total-pages="financingList?.total ? Math.ceil(financingList.total / 10) || 0 : 0"
        size="small"
        @update:current="setPage"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container-list-financing {
  @apply py-2xs px-4xs;

  @apply bg-neutral-high-pure;

  @screen md:system {
    @apply py-2xs px-12 overflow-auto;
    height: calc(100vh - 97px);

    .bank-statement-heading {
      @apply flex justify-between;
    }
  }

  .page-heading {
    @apply flex flex-col gap-4 pb-2xs;

    @screen md:system {
      @apply flex-row items-center justify-between py-2xs;
    }
  }

  .banner {
    @apply h-auto w-full rounded-md mb-2xs px-nano py-nano flex justify-between flex-wrap;
    background: linear-gradient(90deg, #BFFF00 -105.99%, #42CF45 -65.65%, #008859 134.97%);

    @screen md:system {
      @apply mb-xs px-sm py-0;
    }

    .link-banner {
      @apply self-end;
      @apply font-bold;
      @apply mb-4xs mr-4xs;

      @screen md:system {
        @apply m-nano;
      }
    }

    >div {
      >img:nth-child(2) {
        @apply mx-xs w-92px;

        @screen md:system {
          @apply mx-md w-auto;
        }
      }
    }
  }

  .financing-list-header {
    @apply mb-4;

    @screen md:system {
      @apply flex items-baseline justify-between;
    }
  }

  .sol-alert-core.-informative {
    @apply block mb-2xs;

    @screen md:system {
      @apply flex;
    }

    .sol-button-core.-secondary {
      @apply ml-sm mt-micro min-w-max;

      @screen md:system {
        @apply ml-0 mt-0;
      }
    }
  }

  .sol-list-core {
    .list-item > .list-item-row {
      @apply items-start;

      @screen md:system {
        @apply items-center;
      }
    }
  }

  .box-search {
    @apply mb-2xs;
  }

  .empty-state {
    @apply w-full pt-2xs;

    .sol-empty-state-core {
      @apply p-0 max-w-full;
    }
  }

  .sol-select-core.-bottom>.select-options-container {
    @apply w-full;
  }
}

[data-tooltip].tooltip:after {
  width: 450px;
  white-space: break-spaces;
}

@media screen and (max-width: 767px) {
  [data-tooltip].tooltip:after {
    width: 300px;
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
</route>
