<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { SolButton } from '@solfacil/girassol'
import useAuth from './store-v2/auth/useAuth'
import useLogin from './store-v2/auth/useLogin'

import KnowError from './exceptions/KnownError'
import useSearchParams from '~/hooks/useSearchParams'
// services
import keycloakApi from '~/services-v2/keycloack-api/keycloack-api'
import Loading from '~/components/loading/index.vue'

useHead({
  title: 'Financiamentos',
  meta: [
    { name: 'description', content: 'Opinionated Vite Starter Template' },
    {
      name: 'theme-color',
      content: computed(() => isDark.value ? '#00aba9' : '#ffffff'),
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: computed(() => preferredDark.value ? '/favicon-dark.svg' : '/favicon.svg'),
    },
  ],
})

const [searchParams] = useSearchParams()

const code = searchParams.value.get('code') as string | undefined

const {
  needRefresh,
  updateServiceWorker,
} = useRegisterSW()

const {
  isError: isAuthError,
  isLoading: isAuthLoading,
  refetch: refetchAuth,
  error: authErr,
} = useAuth()

const {
  error: loginError,
  isError: isLoginError,
  isPending: isLoginPending,
  mutateAsync: handleLogin,
} = useLogin()

async function handleAuthError() {
  if (!code) // if there is no authorization code, attempt to execute a redirect
    return keycloakApi.redirect()

  // if there an code, attempt to handle login
  await handleLogin(code)
    .catch(keycloakApi.redirect) // If there is an error, attempt to execute a redirect
    .finally(keycloakApi.cleanUrlParams) // Clean the URL params
}

async function retry() {
  await updateServiceWorker()
  await refetchAuth()
}

watch([isAuthLoading, isAuthError], async () => {
  if (isAuthLoading.value)
    return

  if (isAuthError.value && authErr.value instanceof KnowError)
    handleAuthError()
})
</script>

<template>
  <VueQueryDevtools />
  <div v-if="needRefresh" class="centered">
    <Loading />
    <p>Atualizando app...</p>
  </div>

  <div v-if="isAuthLoading" class="centered">
    <Loading />
  </div>

  <div v-else-if="isAuthError || isLoginError" class="centered">
    <div v-if="(authErr instanceof KnowError)">
      <Loading />
      <p>Aguarde. Redirecionando...</p>
    </div>
    <div v-else class="centered">
      <strong>Estamos com problemas técnicos</strong>
      <small>
        Código: {{ authErr?.name }}
      </small>
      <br>
      <SolButton id="retry" size="medium" @click="retry">
        Tentar novamente
      </SolButton>
    </div>
  </div>

  <div v-else-if="isLoginPending" class="centered">
    <Loading />
    <p>Autenticando...</p>
  </div>

  <div v-else>
    <RouterView />
  </div>
</template>

<style scoped>
  .centered {
    display: flex;
    place-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    text-align: center;
    background-color: white;
  }
</style>
