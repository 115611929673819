app:
  name: Financiamentos
  next: Próximo
  advance: Avançar
  advance_and_update: Atualizar e avançar
  share: Compartilhar
  whatsapp: WhatsApp
  send: Enviar
  save: Salvar
  cancel: Cancelar análise
header:
  welcome: Bem-vindo
  exit: Sair
  project:
    title: Financiamento
    admin: Administrador
    int_light: Integrador Light
    steps:
      new: Nova simulação
      result: Resultado
      details: Parcelas
      simulation: Simulação
      formalization: Proposta
      installation: Instalação
      contraction: Contratação
  bnpl:
    steps:
      simulation: Simulação
      formalization: Proposta
      external: Externo
    status:
      IN_PROGRESS:
        type: warning
        text: Em andamento
      EXPIRED:
        type: neutral
        text: Expirado
      DONE:
        type: positive
        text: Concluído
simulation:
  next: Ver parcelas
  load_again: Carregar novamente
  founded: Simulações encontradas
  golist: Ir para a lista
  list: Simulações
  subtitles: Acesse suas simulações salvas
  new: Nova simulação
  return_formalization: Ir para proposta
  project_type: Linhas de financiamento
  client_data: Dados do cliente
  cpf_use_terms:
    client: Eu, o cliente e o devedor solidário, se aplicável, concordamos com os
    terms: Termos de Uso
    with: ', com o'
    terms_authorization: Termo de Autorização de Consulta ao SCR
    and_with: e com a
    privacy_policy: Política de Privacidade
    solfacil: >-
      da Solfácil. O Cliente e o devedor solidário, se aplicável, autorizam a
      consulta de seus dados pessoais junto aos Órgãos de Proteção ao Crédito.
  continue_with_value: Seguir com valor aprovado
  financing_options: Opções de financiamento
  details:
    info_title: Dados da simulação somente para visualização.
    info_description: >-
      Você poderá revisar sua simulação e alterar dados na etapa de assinatura
      de contrato.
  conclude: Concluir simulação
  info_risk:
    RS_title: O cliente foi aprovado na simulação
    RS_description_conditions: 'Fique atento às condições:'
    RS_description_grace: Carência obrigatória de 6 meses.
    RS_description_eligible_area: >-
      Localização em área elegível. A Solfácil sabe o quanto é importante estar
      presente em todas as regiões e está comprometida a fazer isso de um jeito
      eficiente e responsável.
    essential_profile_title: O cliente foi aprovado na simulação
    essential_profile_description: >-
      Atenção! Projeto aprovado em crédito, mas para que possa formalizar esse
      novo projeto, é necessário a comprovação da instalação de outros projetos
      ja formalizados
  modal:
    title: Nova plataforma de proposta!
    altImg: Tela de financiamento
    info:
      proposal:
        title: Mande sua proposta em até 2 minutos!
        description: Preencha tudo de uma vez e aguarde nossa análise.
      projects:
        title: Faça seus projetos quando e onde quiser!
        description: 'Celular, computador ou tablet: você escolhe como e onde.'
      payments:
        title: Pagamentos em tempo real
        description: Após aprovação, pagamentos 24h por dia, 7 dias na semana.
    button:
      old: Seguir com a plataforma antiga
      new: Seguir com a nova plataforma!
  bannerComboFacil:
    title: Kit da Loja + Financiamento = Combo Fácil
    description: Condições e benefícios exclusivos para você e seu cliente
  result:
    data:
      title: Dados da simulação
      name: Nome do projeto
      type:
        cpf: CPF
        cnpj: CNPJ
      client: do cliente
      value: Valor total do projeto
      power: Potência
      system: do sistema
      measure: (kWp)
    financing:
      title: Financiamento
      subtitle: >-
        Ofereça uma solução acessível para o seu cliente investir em energia
        solar. Com prazos flexíveis e condições favoráveis, seu cliente pode
        parcelar seu sistema solar em muitos anos.
      pre_approved_value: Valor pré-aprovado
      max_installments: Prazo pré-aprovado
      to: até
      with_interest: Com juros
      max_grace_period: Carência pré-aprovada
      months: meses
      down_payment: Entrada (cliente para você)
      project_value: Valor financiado solicitado
      kit: Aquisição Kit
      store: Loja Solfácil ou externo
    bnpl:
      banner:
        title: Conheça o | A nova opção de pagamento para seus projetos
        emphasis: Parcelado Solfácil
        description: >-
          Compre o Kit na Loja Solfácil, e receba seu serviço à vista. Seu
          cliente paga em até 9x, e em condição promocional de lançamento, em
          até 3x, não há taxas e tarifas
      title: Parcelado Solfácil
      subtitle: >-
        Adquira seu kit solar na Loja Solfácil, e ofereça essa nova condição
        como mais uma possibilidade de fechar negócios!
      subtitleTwo: >-
        Estamos trabalhando em uma nova opção de pagamento para você e seu
        cliente que em breve você vai poder contar!
      new: Novidade!
      will_be_pre_approved: Confira as condições que seriam pré-aprovadas
      pre_approved_value: Valor pré-aprovado
      approved_with_restrictions: Aprovado, com restrições
      max_installments: Parcelado em até
      to: até
      max_grace_period: Carência pré-aprovada
      without_interest: Sem juros
      without_period: Sem carência
      down_payment: Entrada (cliente para Solfácil)
      of: de
      project_value: Parcelas de até
      kit: Aquisição Kit
      store: Loja Solfácil
      customerRejected:
        title: Cliente reprovado
        description: >-
          Existem 2 ações que você pode tomar: 1. Consulte os órgãos de proteção
          ao crédito e veja se esse CPF ou CNPJ está regular. 2. Consulte este
          CPF ou CNPJ novamente daqui 30 dias. Esse prazo é necessário porque
          pode ser que ocorram alterações nas situações dos mesmos.
        altImg: Imagem representando o cliente sendo reprovado
      alerts:
        responsibility:
          title: Atenção!
          description: >-
            Sabe o cliente que, mesmo sem crédito, você parcela, assumindo o
            risco? Aqui é  semelhante, confira condições.
          action: Saiba mais
        restrictions:
          title: Cliente pré-aprovado com restrições
          description: >-
            Para esse cliente, temos duas opções para seguir com o
            financiamento. Selecione no quadro abaixo.
      shortly:
        title: Parcelado Solfácil
        flag: Em breve
        altImg: Calendário de Período de pagamento
        description: >-
          Em breve uma nova maneira de viabilizar seus projetos junto à Solfácil
          com o parcelado. Clique abaixo para registrar seu interesse e conheça
          às condições.
        textButton: Tenho interesse
      messages:
        noResults:
          title: Ops, algo deu errado
          description: >-
            Ocorreu algum problema e não conseguimos trazer o resultado da sua
            simulação. Tente novamente mais tarde
          textButton: Tente novamente
        analyzingSimulation:
          title: Analisando sua simulação
          description: >-
            Em breve vamos concluir a análise e você será notificado via e-mail.
            Você também pode acompanhar o status dela na listagem de simulações.
          textListButton: Ir para a lista
          textNewSimulationButton: Nova simulação
      modals:
        important:
          title: Importante!
          description: >-
            Você será responsabilizado caso o cliente fique inadimplente. A
            Solfácil poderá solicitar que você pague integralmente os valores do
            parcelamento, bem como poderá inserir os dados da sua empresa nos
            Órgãos de Proteção ao Crédito.
          textButton: Fechar
        attention:
          title: Atenção!
          description: >-
            Você será responsabilizado caso o cliente fique inadimplente. A
            Solfácil poderá solicitar que você pague integralmente os valores do
            parcelamento, bem como poderá inserir os dados da sua empresa nos
            Órgãos de Proteção ao Crédito.
          textCheckbox: Estou ciente
          textCancelButton: Cancelar
          textFollowInstallmentButton: Seguir parcelado
        interest:
          title: Agradecemos seu interesse!
          altImgOption1: Imagem representando as melhores condições
          option1: As melhores condições
          altImgOption2: Imagem representando parcelado Solfácil
          option2: De 3 à 9 vezes!
          altImgOption3: Imagem representando kits adquiridos pela Loja Solfácil
          option3: Kits adquiridos pela Loja Solfácil
          description: >-
            Parcelado Solfácil é a nova condição de pagamentos que a Solfácil
            oferece para você e seus clientes. Comprando o Kit na Loja Solfácil,
            você tem mais essa opção para fechar negócio!
          textButton: Fechar
      buttons:
        textFollowFinancing: Seguir financiamento
        textFollowInstallment: Seguir parcelado
        textInterest: Tenho interesse
  system_power_error_422: Reveja a potência desse projeto
  bnpl:
    solfacilInstallment: Parcelado Solfácil
    identity: id
    installment_title: Parcela | Parcelas
    conditions_installment: >-
      Confira nossas opções a que melhor se encaixa no perfil do seu cliente. É
      possível simular o valor do kit do projeto, e defini-lo na etapa seguinte,
      de proposta.
    project_value: Valor do projeto
    due_date: Vencimento
    invoice_due_date: Vencimento da fatura
    kit_value: Valor do kit
    kit_value_helper: Atualizado após a seleção do kit
    number_order: N° do pedido
    service_value: Valor do serviço
    service_value_helper: Valor do projeto menos kit e taxa
    installment_amount: Valor da parcela
    initial_amount: Entrada
    receive_amount: Receberá
    installments:
      customer_pays:
        title: Parcela com o *serviço fixo*
        description: >-
          Você repassa o valor da taxa para seu cliente, aumentando o valor do
          projeto.
      customer_not_pays:
        title: Parcela com o *projeto fixo*
        description: Você arca com o valor da taxa, mantendo o valor do projeto.
    banner:
      title: Projetos parcelados
      description: >-
        Encontre seus projetos em andamento do nosso novo modelo de pagamento
        clicando no botão ao lado.
      tooltip: >-
        Agora além de financiamento, você pode ofertar ao seu cliente a nova
        linha "Parcelado Solfácil". Essa opção pode estar disponível na tela de
        "Resultado da simulação".
      button: Ver projetos
      black:
        title: Novo Crédito "Parcelado Solfácil"
        description: Até 9 parcelas, com condições exclusivas!
        textButton: Saiba mais
    modal:
      title: 'Novidade: Parcelado Solfácil'
      altImgOption1: Imagem representando as melhores condições
      option1: As melhores condições
      altImgOption2: Imagem representando parcelado Solfácil
      option2: De 3 à 9 vezes!
      altImgOption3: Imagem representando kits adquiridos pela Loja Solfácil
      option3: Kits adquiridos pela Loja Solfácil
      description: >-
        Parcelado Solfácil é a nova condição de pagamentos que a Solfácil
        oferece para você e seus clientes. Comprando o Kit na Loja Solfácil,
        você tem mais essa opção para fechar negócio! Disponível no resultado da
        simulação.
      textButton: Fechar
    id:
      summary:
        title:
          summary: Resumo
          service: Serviço fixo
        installment:
          title: Parcela
          prohibited: Entrada
          cet: CET
          am: a.m.
        enterprise:
          title: Para sua empresa
          total: Valor total do projeto
          kit: Kit da Loja
          tax: Taxa de antecipação
          value: Valor a receber
        client:
          title: Para seu cliente
          total: Valor total do projeto
          prohibited: Entrada
          installments: Parcelas
          of: de
  notFound:
    title: Nenhuma simulação encontrada
    subtitle: >-
      Busque por outro Nome, CPF, ou CNPJ para encontrar a simulação que está
      buscando. Caso esteja correto, faça uma nova simulação, clicando no botão
      abaixo.
  errorProject:
    title: Erro ao carregar a lista
    subtitle: >-
      Infelizmente não conseguimos exibir a sua lista de simulações nesse
      momento. O que deseja fazer?
  status_filter:
    done: Aprovado
    pending: Em processamento
    reproved: Reprovado
revision:
  title: Revisão da simulação
  subtitle: >-
    Revise e altere todos os dados da simulação, inclusive valores, desde que
    estejam dentro das nossas políticas.
  subtitle_complement: Caso contrário, será necessário realizar uma nova simulação.
buttons:
  back: Voltar
loading:
  messages: >-
    Estamos quase lá, Captando energia solar para alimentar nossos cálculos, Os
    raios solares estão chegando, Carregando com a força do sol
list:
  days: '- | {count} dia | {count} dias'
installments:
  banner:
    title: 'Combo Fácil:'
    start: Compre o kit na
    link: Loja Solfácil
    end: >-
      com este financiamento, tenha parcelas mais baratas para seu cliente e
      ganhe muito mais pontos Solfácil Mais.
  with_combo: Parcelas com Combo Fácil
  with_combo_description: >-
    A parcela mais barata para o seu cliente. Kit deve ser comprado na Loja
    Solfácil.
  with_combo_comission: Parcelas com Combo Fácil e comissão
  with_combo_comission_description: >-
    Melhor custo benefício para você e seu cliente. Kit deve ser comprado na
    Loja Solfácil.
  without_combo: Parcelas sem Combo Fácil
  without_combo_description: >-
    Opção mais cara para seu o cliente. Kit pode ser comprado fora da Loja
    Solfácil.
form:
  alert_required_fields: Por favor, preencha todos os campos obrigatórios.
  required: Campo obrigatório
  file_required: É necessário anexar um arquivo.
  invalid_file_type: |
    Tipo de arquivo inválido. Tipos de arquivo aceitos: {types}
  invalid_document: Documento deve ser válido
  invalid_phone: Número deve ser válido
  invalid_email: E-mail deve ser válido
  invalid_emails: Insira um email válido. Se houver mais de um, separe-os com vírgulas.
  name: Nome Completo
  cpf: CPF
  cnpj: CNPJ
  cepNotFound: CEP não encontrado
  cepInfo: >-
    O CEP precisa ser o mesmo utilizado para entregas dos Correios, mesmo que
    conste outro na conta de luz. Caso a dúvida persiste, clique no botão abaixo
    "Problemas com o CEP?".
  informativeCepText: Problemas com o CEP?
  validatedCep: CEP validado com sucesso.
  phone: Celular
  email: E-mail
  validatedEmail: E-mail validado com sucesso.
  nationality: Nacionalidade
  birthdate: Data de Nascimento
  occupation: Profissão
  gender: Gênero
  mother_name: Nome da mãe
  energy_concessionaire: Concessionária
  installation_phase: Fase do local da instalação
  inverter: Marca e modelo do inversor
  module_quantity: Quantidade de módulos
  modules: Marca e modelo dos módulos
  microinversor_quantity: Quantidade de microinversores
  microinversors: Marca e modelo dos microinversores
  button:
    add_inverter: Adicionar inversor
  cash: À vista
  installments: Parcelado
  hardware_invoice: Nota fiscal do equipamento
  service_invoice: Nota fiscal cheia do projeto
  commission_invoice: Nota fiscal de comissão
  error: Cadastro não realizado
  cepModal:
    title: Problemas com o CEP?
    titleButton: Fechar
    informative:
      title: >-
        Caso tenha problemas com o CEP, assista ao vídeo ou siga os passos
        abaixo:
      item1: '1º Acesse ao site Buscar CEP dos correios:'
      item2: >-
        2º No primeiro campo, escreva o nome da rua, cidade e Unidade Federal
        (abreviado). No segundo campo não é necessário fazer alterações.
      item3: 3º Preencha o código exibido na imagem, e clique no botão “Buscar”.
      item4: >-
        4º Na tela seguinte vai aparecer o CEP que precisa ser informado na
        plataforma.
      item5: >-
        5º Se as dúvidas persistirem, assista o vídeo acima para mais
        informações
  cancelAnalysis:
    confirm: Confirmar cancelamento
    back: Voltar
    title: Cancelar a análise?
    message: >-
      Você está prestes a cancelar a análise das informações inseridas. Isso
      interromperá o processo, e um novo envio será necessário. Tem certeza que
      deseja continuar?
    successToast: Análise cancelada com sucesso.
    errorToastDescription: Não foi possível cancelar a análise.
company:
  information: Dados da empresa
  name: Razão social
  phone: Telefone corporativo
  email: E-mail corporativo
  document: CNPJ
  energy_bill: Conta de luz
  energy_bill_subtitle: >-
    Iremos solicitar o envio da conta de luz na etapa de documentação. Se não
    estiver no nome da empresa, basta indicar o CNPJ ou CPF do local da
    instalação.
  energy_bill_is_the_same_of_client: A conta de luz está no nome do seu cliente?
  majority_section: Dados do sócio majoritário
  majority_subtitles: >-
    Sócio majoritário é aquele que possui a maior participação dentro do quadro
    societário da empresa. Essa pessoa se enquadra como devedor solidário.
  majority_is_the_same_of_the_legal: Sócio majoritário, é o mesmo do representante legal?
  has_more_than_one_legal_representant: Na empresa, existe apenas um representante legal?
  legal_representant_section: Dados do representante legal
  legal_representant_subtitle: >-
    Responsável por desempenhar todas as funções administrativas da empresa
    respondendo legalmente pelo CNPJ e outras ações gerenciais devidamente
    indicadas no contrato social.
  second_legal_representant_section: Dados do segundo representante legal
  alertDocumentTitle: >-
    Notamos que o endereço na conta de luz, está diferente do endereço de
    instalação
  alertDocumentMessage: >-
    Revise os campos abaixo com o local de instalação para confirmar, ou anexe
    uma nova conta de luz do local da instalação.
  alertRepresentantLegalMissingTitle: >-
    Notamos que o cliente PJ precisa preencher informações sobre representante
    legal
  alertRepresentantLegalMissingMessage: Confira e confirme abaixo os dados do representante legal
customer:
  new: Proposta
  confirm: Confirmar dados
  client_data: Dados do cliente
  client_data_subtitle: Responda os campos abaixo com os dados do seu cliente
  use_terms: >-
    Eu e o cliente concordamos com os Termos de Uso, com o Termo de Autorização
    de Consulta ao SCR e com a Política de Privacidade da Solfácil. O Cliente
    autoriza a consulta de seus dados pessoais junto aos Órgãos de Proteção ao
    Crédito.
  confirm_modal: >-
    Não é possível fazer alterações nos dados do cliente futuramente. Uma vez
    confirmado, caso seja necessário fazer alterações um novo projeto deverá ser
    criado.
  attention: Atenção
  document_data_title: Documentação
  document_data_subtitle: Anexe os seguintes documentos abaixo do seu cliente.
  address_data_title: Endereço residencial
  address_data_subtitle: Preencha igual está na conta de luz
  project_data_title: Equipamentos
  project_data_subtitle: >-
    Adicione as informações técnicas e o equipamento. Caso você opte por Combo
    Fácil não é necessário informar os equipamentos.
  receipt_model_title: Modelo de recebimento
  receipt_model_subtitle: >-
    Escolha a forma como você vai receber o valor do seu projeto e anexe a(s)
    nota(s) fiscal(is).
  method_receipt: Forma de recebimento
  in_cash_subtitle_with_combo: >-
    Todo valor, descontado o kit, à vista para você após a aprovação do(s)
    pedido(s) vinculado(s) ao projeto.
  in_cash_subtitle_without_combo: >-
    Todo valor à vista para você após aprovação do contrato e nota(s)
    fiscal(is).
  in_installment_subtitle: >-
    Primeiro pagamento após aprovação do contrato e nota(s) fiscal(is), segundo
    pagamento após validação da instalação. | O valor dos equipamentos será pago
    à vista para o fornecedor. Metade do valor restante financiado será pago
    após aprovação do contrato e nota(s) fiscal(is), a outra metade após
    validação da instalação.
  in_installment_without_service_invoice_subtitle: >-
    Primeiro pagamento após aprovação do contrato, segundo pagamento após a
    validação da instalação.
  invoice: Notas fiscais |  Nota fiscal
  invoice_subtitle: >-
    O envio das notas pode ser feito após a assinatura do contrato. Depois de
    assinado você vai ter 10 dias para submeter as notas, caso contrário sua
    empresa ficará bloqueada para novos projetos. Dê preferência para arquivos
    no formato .pdf. | O envio da nota pode ser feito após a assinatura do
    contrato. Depois de assinado você vai ter 10 dias para submeter as notas,
    caso contrário sua empresa ficará bloqueada para novos projetos. Dê
    preferência para arquivos no formato .pdf.
  transfer_summary: Resumo da transferência
  financed_value: Valor financiado
  kit_value: Valor do kit (Combo Fácil)
  waiting_kit_value: Aguardando pedido (Combo Fácil)
  opened: Em aberto
  equipment_value: Valor do kit (Pago ao fornecedor)
  transfer_after_contract_approval: Transferência após aprovação do contrato
  transfer_after_project_approval: Transferência após aprovação do projeto
  transfer_after_validation_installation: Transferência após validação da instalação
  transfer_after_project_formalization: Transferência após formalização do projeto
  total_transfer_to_registered_account: Total que vamos transferir para conta cadastrada
  after_contract_approval_value_will_be_transferred: Após a aprovação do contrato o valor será transferido para sua conta.
  after_installation_value_transfer: >-
    Após a aprovação da instalação o valor bloqueado será transferido para sua
    conta.
  commission_transfer_after_nf_approval: >-
    O valor da comissão será transferido após a aprovação da NF de comissão. O
    valor bloqueado será transferido após a validação da instalação.
  value_transfer_after_validation_installation: O valor bloqueado será transferido após a validação da instalação.
  financing_commission: Comissão financiamento
  insurance_commission: Comissão seguros
  service_products: Nota fiscal cheia do projeto emitida pela sua empresa
  alertDocumentTitle: >-
    Notamos que o endereço na conta de luz, está diferente do endereço de
    instalação
  alertDocumentMessage: >-
    Revise os campos abaixo com o local de instalação para confirmar, ou anexe
    uma nova conta de luz do local da instalação.
  alertInstallment: >-
    Você receberá o pagamento em duas parcelas: após aprovação do contrato e
    após aprovação da instalação. Ainda somos responsáveis pelo pagamento do kit
    do seu projeto, por isso pedimos dados do fornecedor.
  method_receipt_seleted_in_cash: >-
    Neste cenário a Solfácil paga à vista: 100% do dinheiro para você e você
    paga o fornecedor.
  method_receipt_seleted_installment: >-
    Neste cenário a Solfácil paga 60% do valor do projeto na formalização. Os
    40% restantes serão pagos na conclsão da instalação.
  separate_invoices_subtitle: >-
    Nota de serviço emitida pela sua empresa + nota de equipamentos emitida pelo
    fornecedor
  second_separate_invoices_subtitle: >-
    Neste cenário a Solfácil paga o fornecedores. O restante é enviado para
    você.
  supplier_information: Informações do fornecedor
  supplier_equipment: Fornecedor do equipamento
  invoice_value: Valor da nota fiscal do kit
  invoice_value_placeholder: Digite o valor da nosta fiscal do kit
  emails_to_send: E-mail para envio da carta de faturamento
  emails_to_send_placeholder: Digite o e-mail para envio da carta de faturamento
  emails_tooltip: |-
    Para você ter acesso à nota fiscal de venda
    desses equipamentos,precisamos enviar
    uma carta de faturamento para o seu fornecedor.
    Para isso, preencha abaixo
    o e-mail do seu contato neste fornecedor
  titleUnableContinuefinancing: Não conseguimos seguir com esse financiamento
  descriptionUnableContinuefinancing: >-
    Devido a inconsistências nos dados desse cliente, não seguiremos com essa
    proposta de financiamento
  endButtons:
    backSimulation: Voltar para simulação
    nextInstallation: Ir para instalação
  header:
    title: Projeto
    steps:
      simulation: Simulação
      formalization: Proposta
      installation: Instalação
    tags:
      pending: Em andamento
      pendency: Pendenciado
      kit_pending: Kit pendente
      under_analysis: Em análise
      submitted: Enviado
      send_contract: Enviar contrato
      sent: Pendente cliente
      reproved: Reprovado
      canceled: Cancelado
      approved: Aprovado
    message:
      error: Ocorreu um erro ao obter os status da proposta.
  biometry:
    title: Biometria e contrato
    subtitle: >-
      Informe seu cliente como é o processo da realização da biometria e
      assinatura do contrato do financiamento.
    steps:
      send_contract: Envio da biometria e do contrato
      biometry: Biometria
      contract: Contrato
      approval: Aprovação do contrato
    description:
      send_biometry_contract:
        days: >-
          Após você clicar em "Enviar", o seu cliente vai receber um e-mail com
          o link para realizar a biometria facial e depois outro para assinar o
          contrato eletronicamente. O cliente terá {days} dias para completar
          cada uma das duas etapas. Se não, o link vai expirar e um novo e-mail
          será enviado automaticamente;
        minutes: >-
          Caso o seu cliente não tenha recebido o e-mail da biometria ou do
          contrato, é possível reenviar {minutes} minutos após o primeiro envio;
        sign: >-
          Para assinar o contrato é necessário informar um Token (código de
          confirmação) recebido pelo celular. Selecione abaixo a melhor forma
          para o seu cliente receber o Token.
        radio:
          label: Forma de recebimento do Token
          sms: SMS
          whatsapp: WhatsApp
      biometry: >-
        O seu cliente vai receber um e-mail com as instruções para realizar a
        biometria facial. São aceitas 5 tentativas para fazê-la com sucesso,
        caso contrário o projeto é reprovado.
      contract: >-
        Após realizada a biometria, seu cliente vai receber outro e-mail, para
        assinar o contrato. Peça para que ele revise as condições antes de
        assinar. Durante essa etapa, será solicitado o preenchimento do Token
        (enviado conforme selecionado no campo acima). Depois de assinado, o
        contrato é enviado ao e-mail do cliente.
      approval: Etapa de confirmação e aprovação do time Solfácil
    alert:
      under_analysis:
        title: Contrato e biometria realizados pelo cliente e está em análise
        text: >-
          Nosso time está analisando a documentação e em breve traremos a
          resposta.
      pending:
        title: Problema no resultado da biometria
        text: Um novo e-mail já foi enviado ao cliente para uma nova tentativa.
      reproved:
        title: Cliente reprovado na biometria ou assinatura do contrato
        subtitle: >-
          Seu cliente foi reprovado nessa etapa devido a inconsistências e por
          isso esse projeto não pode ser formalizado. Esse tipo de reprova é
          irreversível.
      pendencies: >-
        Regularize sua situação conosco até {date} para seguir com a
        formalização desse projeto.
    status:
      sent_message: Enviado
      waiting: Aguardando
      in_progress: Em andamento
      pending: Enviar contrato
      pendency: Pendenciado
      sent: Pendente cliente
      under_analysis: Em análise
      reproved: Reprovado
      approved: Aprovado
      accomplished: Realizada
      signed: Assinado
    footer:
      policy: Acessar política de cancelamento
      resend: Reenviar
      cancel: Cancelar envio
      contract: Baixar contrato
      insurance: Baixar apólice
    modal_cancel:
      title: Deseja cancelar envio do contrato e biometria?
      description: >-
        Cancelar o envio da biometria e do contrato podem atrasar a formalização
        desse cliente. Caso a biometria já tenha sido realizada, será necessário
        que o cliente faça novamente.
      confirm: Cancelar envio
      cancel: Fechar
    catch:
      data_error: >-
        Atenção! Ocorreu um erro ao obter os dados da biometria. Tente
        novamente.
      send_error: >-
        Atenção! Ocorreu um erro ao enviar o contrato e a biometria. Tente
        novamente.
      resend_error: >-
        Atenção! Ocorreu um erro ao reenviar o contrato e a biometria. Tente
        novamente.
      cancel_error: >-
        Atenção! Ocorreu um erro ao cancelar o contrato e a biometria. Tente
        novamente.
  bannerSolfacilPlus:
    titlePrimary: Parabéns pela formalização! Você ganhou
    titleSecondary: pontos no Solfácil Mais
  canceled:
    title: Projeto cancelado
    description: >-
      O cliente solicitou o cancelamento deste projeto e a transferência de
      valores não será realizada. Se já foi feita, ela será estornada. Em caso
      de Combo Fácil, o pedido do kit também será cancelado.
solfacilPoints:
  label: Pontos Solfácil Mais
  phrases:
    one: 'Que tal adicionar em seu financiamento:'
    two: Vamos melhorar o projeto? Que tal adicionar
    three: Bom projeto! Que tal adicionar {string}?
    four: Formalizar esse projeto vai te trazer muitos benefícios! Muito bem!
toastKitSelected:
  title: Kit selecionado
  description: >-
    Caso disponível no momento da formalização, o kit será faturado
    automaticamente para sua comodidade.
company_form_document:
  label_document_majority_and_legal_representants: >-
    RG ou CNH do sócio majoritário e representante(s) legal(is) (caso
    aplicável).
  label_energy_bill: Conta de luz do local da instalação.
  label_document_company: Documentos de oficialização da empresa.
  tooltip_accepted_documents: |-
    Exemplo de documentos aceitos:

     Declaração de micro empreendedor individual (MEI);
     Contrato Social;
     Requerimento de empresário;
     Certidão emitida pela Junta Comercial.
formDocument:
  labelDocument: RG ou CNH*
  labelDocumentSol: RG ou CNH do cliente
  labelElectricityBill: Conta de luz do local da instalação
  placeholder: Arraste o arquivo aqui ou clique para upload
  helperText: Somente arquivos .jpg com tamanho máximo de arquivo de 500kb
  errorMessage: Inisira um novo documento
  errorMessageInstallationAddressMatchesBill: >-
    Conta de luz não bate com local de instalação. Envie novamente o documento
    de conta de luz e caso necessário atualize o endereço de instalação em
    'Dados do cliente'.
  errorMessageInstallationDocumentMatchesBill: Documento não bate com CPF registrado.
  errorMessageInstallationDocumentMatches: CPF não bate com documento registrado
  errorMessageInstallationAddressAndDocumentMatchesBill: >-
    Conta de luz não bate com local de instalação e com o documento informado.
    Envie novamente o documento de conta de luz, caso necessário atualize o
    endereço de instalação e o documento em 'Dados do cliente'.
  errorMessageIdentityMatchesDocument: Documento não bate com CPF registrado, faça o upload do arquivo novamente.
  messageTitleAwaitingCustomerData: Aguardando Dados do cliente
  messageTextAwaitingCustomerData: >-
    Para finalizar a análise dos documentos abaixo, por favor faça o
    preenchimento da etapa de "Dados do cliente".
  alertError: Inconsistência na análise da documentação
  responseError: >-
    Erro ao enviar o arquivo, verifique o tipo do arquivo ou o tamanho e tente
    novamente.
formAddress:
  countryside: Endereço está em Zona Rural?
  yesCountryside: Sim
  noCountryside: Não
  cep: CEP*
  installationStreet: Rua da instalação*
  street: Rua*
  number: Número*
  complement: Complemento
  complementPlaceholder: Apt, bloco...
  neighborhood: Bairro*
  state: Estado*
  city: Cidade*
  local: Local de residência do cliente, é o mesmo local da instalação?
  yesLocal: Local de residência, é o mesmo do local da instalação
  noLocal: Local de residência e instalação não são os mesmo
  titleInstallationLocation: Complete as informações do local de instalação
  addressInstallation: Endereço do local de instalação
subscriptionEnergy:
  title: Seu cliente ainda pode ter energia solar!
  subtitle: >-
    Indique seu cliente para ter energia solar por assinatura com até 20% de
    desconto na conta de luz! E ganhe uma comissão caso ele aceite!
  recommend_customer: Indicar cliente
  alt_img: Imagem Energia Solar por Assinatura
  title_img: Energia Solar por Assinatura
electricityBill:
  yesMessage: Sim
  noMessage: Não
  title: Conta de luz
  description: >-
    Iremos solicitar o envio da conta de luz na etapa de documentação. Se não
    estiver no nome do cliente, basta indicar o CPF do titular.
  question: A conta de luz está no nome do seu cliente?
  placeholder: Informe o CPF que consta na conta
  errorMessageInstallationDocumentMatchesBill: CPF não bate com documento registrado
  errorMessageInstallationDocumentMatchesBillCompany: CNPJ não bate com documento registrado
  errorMessageCompany: Documentos da empresa não batem com o registrado
emptyStateCustomer:
  title: Sem informações disponíveis
  subtitle: Não há informações sobre os dados do cliente.
installationPage:
  messageNewInstallation: Instalação
  barInstallation:
    waiting:
      type: informative
      text: Em andamento
    processing:
      type: warning
      text: Em análise
    error:
      type: negative
      text: Pendenciado
    success:
      type: positive
      text: Instalado
  helpButton: Dúvidas na ativação
  manualButton: Seguir manualmente
  amperaExperienceButton: Experiência Ampera
  waiting:
    img:
      name: ampera.svg
      alt: Imagem do equipamento ampera
      title: Ampera
    title: Aguardando ativação automática do Ampera
    subtitle: >-
      Caso faça mais de 15 minutos que você ativou o Ampera e ele ainda não foi
      ativado, clique no botão abaixo para adicionar fotos (ou vídeos) e
      comprovar a instalação
  waitingMultiples:
    img:
      name: ampera.svg
      alt: Imagem do equipamento ampera
      title: Ampera
    title: >-
      Ao concluir a instalação, adicione fotos ou vídeos do seu projeto nos
      campos abaixo
    subtitle: >-
      Com o Ampera ativado após a instalação você não precisa passar por essa
      etapa. Porém como você ainda não tem 3 projetos instalados na Solfácil,
      precisamos que você anexe alguns arquivos para que possamos validar a
      instalação do seu projeto.
  waitingAmperaActivated:
    img:
      name: ampera.svg
      alt: Imagem do equipamento ampera
      title: Ampera
    title: Seu Ampera já foi ativado, você já quase concluiu seu projeto
    subtitle: >-
      Precisamos de fotos ou vídeos para validar esta instalação, mas após a
      terceira instalação, os projetos seguintes com Ampera, você pula essa
      etapa. O Ampera valida a instalação para você!
  waitingWihtoutAmpera:
    img:
      name: installation.svg
      alt: Imagem representando instalção
      title: Instalação
    title: >-
      Ao concluir a instalação, adicione fotos ou vídeos do seu projeto nos
      campos abaixo
    subtitle: >-
      Cansado de subir fotos pra comprovar instalação? Com Ampera em seus
      projetos, além de contar com monitoramento inteligente das suas usinas,
      com a ativação do Ampera, você não precisar subir mais nada para comprovar
      sua instalação!
  waitingWihtoutAmperaMultiples:
    img:
      name: installation.svg
      alt: Imagem representando instalção
      title: Instalação
    title: >-
      Ao concluir a instalação, adicione fotos ou vídeos do seu projeto nos
      campos abaixo
    subtitle: >-
      Com o Ampera ativado após a instalação, você pula essa etapa! Porém, como
      você ainda não tem 3 projetos na Solfácil, envie alguns arquivos para
      validar sua instalação e desbloquear todo o potencial do Ampera!
  processing:
    img:
      name: processing.svg
      alt: Imagem representando algo em processamento
      title: >-
        Estamos analisando se está tudo certo com o Ampera, em até 24h úteis
        confirmaremos ou não a instalação.
    title: Obrigado por informar a conclusão da instalação!
    subtitle: >-
      Estamos analisando se está tudo certo com o Ampera, em até 24h úteis
      confirmaremos ou não a instalação.
  error:
    img:
      name: error.svg
      alt: Imagem representando que ocorreu um erro
      title: Por favor revise as informações abaixo.
    title: Encontramos um problema para seguir com a conclusão da instalação
    subtitle: Por favor revise as informações abaixo.
  success:
    img:
      name: success.svg
      alt: Imagem representando que houve sucesso
      title: Concluído
    title: Instalação validada com sucesso!
    subtitle: Concluído em {date} às {hour}
  titleAddFiles: Adicione fotos e vídeos da instalação
  descriptionAddFiles: São aceitos diversos formatos com tamanho máximo de 20Mb.
  descriptionAvoidFailures: >-
    Evite reprovas! Caso a marca do inversor tenha sido alterada, indique o
    modelo/potência nos comentários adicionais. São aceitos diversos formatos
    com tamanho máximo de 20Mb.
  form:
    placeholderUpload: Arraste o arquivo aqui ou clique para upload
    installationField:
      label: Do telhado ou local da instalação
    invertersField:
      label: Contendo inversores e proteções CC/CA
    installedField:
      label: Do Ampera instalado
    additionalComments: Comentários adicionais
    sendButton: Enviar para análise
    downloadFiles: Baixar arquivos
  footer:
    formalization: Ir para proposta
    ampera: Monitorar Ampera
emptyStateInstallation:
  title: Sem informações disponíveis
  subtitle: Não há informações sobre a instalação.
bannerSolfacilPlus:
  img:
    alt: Imagem representando uma conquista.
    title: Parabéns! Você ganhou pontos extras no Solfácil Mais
  title: Parabéns! Você ganhou pontos extras no Solfácil Mais
  subtitle: Quanto mais projetos fizer com a Solfácil, maior seu nível e benefícios!
receiptModel:
  infoSolfacil:
    title: Informações para emissão da nota fiscal de comissão
    subtitle: >-
      Favor emitir e anexar uma nota fiscal para a Solfácil referente ao
      pagamento da sua comissão.
    labelValue: Valor da nota
    labelCnpj: CNPJ
    labelCorporateReason: Razão social
    labelMunicipalRegistration: Inscrição Municipal (IM)
    labelMainActivity: Atividade principal (CNAE)
    labelAddress: Endereço
    labelEmail: E-mail
  alert:
    title: >-
      Para ter acesso ao Modelo de recebimento primeiro você precisa escolher um
      kit do Combo Fácil
    text: >-
      Volte a “Dados técnicos e equipamentos” para selecionar um kit da vitrine
      ou acesse a Loja compre um kit e opte pela opção de pagamento
      "Financiamento Solfácil”. Desta forma você conseguirá seguir com o Modelo
      de recebimento.
    upload_not_sent: Arquivo não enviado, tente novamente!
  alertInstallments:
    title: Modelo de recebimento em dois pagamentos ({firstValue}% e {secondValue}%)
    text: >-
      Após a aprovação do contrato, você receberá {firstValue}% do valor do
      projeto, descontados o valor do kit. Concluída a aprovação instalação os
      restantes {secondValue}%.
  modalInstallments:
    titleWithCombo: Pagamento parcelado com compra de kit pela Solfácil
    titleWithoutCombo: Pagamento parcelado
    principalText: >-
      Para o seu perfil de parceiro, nossa política de pagamento é {firstValue}%
      após aprovação do contrato e {secondValue}% após a aprovação da
      instalação. Você poderá receber todo valor à vista
    firstTopic: Quando tiver três projetos instalados com a Solfácil;
    secondTopic: Se tiver no máximo 1 projeto atrasado com mais de 120 dias de instalação.
  onflex:
    title: Modelo de recebimento 100% após a instalação
    subtitle: >-
      O valor do financiamento, menos o valor do kit que a Solfácil paga ao
      fornecedor, será transferido após a validação da instalação deste projeto.
    principalText: >-
      Com o cadastro Essencial você recebe 100% do valor financiado, descontando
      o valor do kit, após a instalação. Evoluindo sua situação cadastral você
      tem acesso a outros modelos de recebimento.
    topic: 'Para evoluir sua situação cadastral você pode:'
    firstTopic: Solicitar uma reavaliação com o Atendimento Solfácil
    secondTopic: Aguardar a próxima reavaliação automática trimestral
  error:
    title: Erro ao carregar modelo de recebimento!
    subtitle: Ocorreu um erro ao tentar carregar o modelo de recebimento.
  response:
    error: >-
      Ocorreu um erro ao tentar atualizar o projeto, verifique se existe algum
      contrato gerado.
    equipment_value_error: O valor do equipamento não pode ser maior que o valor do financiamento.
    email_error: >-
      Ocorreu um erro ao tentar atualizar o projeto, verifique se os e-mails
      estão corretos.
  tags:
    inAnalysis: Em análise
    approved: Aprovado
    denied: Reprovado
    inProgress: Em andamento
    pendingChange: NF Pendente
    selected: Selecionado
    transferInProgress: Transferência em andamento
project_data:
  status:
    pending: Pendenciado
    selected: Selecionado
    in_progress: Em andamento
    kit_pending: Kit pendente
    approved: Aprovado
  alerts:
    kit_mirror_pending:
      title: Adicione o Kit do projeto adquirido através da Loja
      text: >-
        Necessário enviar o Kit do seu projeto através do espelho do pedido até
        15 dias após a formalização do seu contrato, caso contrário seu perfil
        de parceiro será bloqueado para novas formalizações.
    kit_out:
      title: O kit que você selecionou na simulação não está mais disponível
      text: >-
        Vamos precisar que você selecione um novo kit dentro dos disponíveis na
        loja.
    invalid_kit:
      title: >-
        O kit que você selecionou está abaixo ou acima das necessidades do
        projeto do seu cliente
      text: >-
        Já filtramos os kits para aqueles que atendam a sua necessidade e do
        cliente.
    mirror:
      in_analisys: >-
        Estamos analisando o espelho do pedido, este processo leva alguns
        instantes.
      info_payment_method_store: >-
        Compre pela Loja Solfácil e selecione o método de pagamento
        “Financiamento Solfácil”. Depois de comprar, adicione o espelho do
        pedido no campo abaixo.
      info_payment_method_store_button: Ir para a Loja
    mirror_uploaded:
      text: Kit solar selecionado via espelho do pedido
      action_text: Baixar espelho do pedido
  heading:
    kit: Kit da Loja
  notifications:
    mirror_uploaded: >-
      Estamos analisando o espelho do pedido, este processo leva alguns
      instantes.
    mirror_upload_error: Erro ao enviar espelho da Loja. Revise o PDF adicionado.
    mirror_upload_success: Espelho do pedido da Loja anexado com sucesso.
    mirror_uploaded_reproved: Reveja o espelho do pedido.
  errors:
    failed_to_save_kit_draft: Não foi possível realizar a ação. Por favor, tente novamente
    string_overload_max: >-
      Potência excessiva no(s) inversor(es) selecionado(s). Diminua a quantidade
      ou opte por modelos de menor potência.
    string_overload_min: >-
      Potência insuficiente no(s) inversor(es) selecionado(s). Adicione mais um
      inversor ou escolha modelos de potência superior.
    microinverter_overload_max: >-
      Potência excessiva nos microinversores selecionados. Aumente a quantidade
      ou opte por modelos de menor potência.
    microinverter_overload_min: >-
      Potência insuficiente nos microinversores selecionados. Diminua a
      quantidade de microinversores ao projeto ou escolha um modelo de potência
      superior.
    modules_overload: >-
      Os módulos selecionados parecem não se adequar ao especificado no projeto.
      Por favor revise a quantidade ou potência  dos módulos.
  form:
    mirror_upload: Adicione o espelho do pedido
    mirror: Via espelho do pedido (Comprar na Loja)
    showcase: Via vitrine da Loja (Mais rápido)
    inverter_type: Tipo de inversor
    combo_option:
      label: Selecione como prosseguir
      subtitle: >-
        Nesse primeiro momento você apenas informa os equipamentos que você
        pretende usar no projeto
financing:
  filters:
    title: 'Filtrar projetos por:'
    status: Status
    steps: Etapas
    clear: Limpar filtro | Limpar filtros
  title: Financiamentos
  subtitle: >-
    Todos as formalizações e instalações da nova plataforma reunidas em um só
    lugar
  alert:
    title: Aqui você encontra apenas financiamentos criados na nova plataforma
    subtitle:
      start: Projetos ainda em simulação se encontram na
      link: lista de simulações
      end: . Demais financiamentos se encontram na lista antiga.
    action: Ver lista antiga
  banner:
    title: Parcelas mais baixas para você e seu cliente
    subtitle: Suba de nível mais rápido no Solfácil Mais e tenha sempre o melhor preço
  button:
    know_more: Saiba mais
  boxSearch:
    placeholder: Buscar por Nome, CPF/CNPJ ou ID
    button: Buscar
  emptyList:
    title: Sem financiamentos em andamento na nova plataforma
    subtitle: >-
      Inicie uma nova simulação, confira suas simulações em andamento ou acesse
      seus financiamentos em andamento pela lista da plataforma antiga.
    button_list: Ver lista de simulações
  notFound:
    title: Nenhum projeto encontrado
    subtitle: >-
      Busque por outro Nome, CPF, CNPJ ou ID do projeto para encontrar o projeto
      que está buscando. Caso esteja correto, você pode buscar o projeto lista
      antiga.
  sort_by:
    label: 'Ordenar por:'
    updated_at_desc: Últimas atualizações
    created_at_desc: Mais recentes
    created_at_asc: Mais antigos
    expires_at_asc: Expiração
  no_data: Sem dados aqui
  list:
    results_found: '{count} financiamento encontrado | {count} financiamentos encontrados'
    days_info: '- | {count} dia | {count} dias'
    headers:
      id: ID
      customer_name: Nome do projeto
      financed_value: Valor financiado
      expires_at: Expira em
      installing_days: Instalando há
      installed_days: Instalado em
      step: Etapa
    status:
      pending: Em andamento
      expired: Expirado
      reproved: Reprovado
      approved: Aprovado
      under_analysis: Em análise
      pendency: Pendenciado
      send_contract: Enviar contrato
      pending_customer: Pendente cliente
      pending_receipt: NF pendente
      concluded: Concluído
      cancelled: Cancelado
      kit_pending: Kit pendente
      canceled: Cancelado
    tags:
      paid_percentage: Pago {percentage}%
    step:
      simulation: Simulação
      formalization: Proposta
      installation: Instalação
      concluded: Concluído
      expired: Expirado
      canceled: Cancelado
    error:
      fetching:
        title: Não conseguimos carregar os financiamentos
        description: Tente novamente mais tarde.
