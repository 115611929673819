<script setup lang="ts">
import { SolAlert, SolDivider, useToast } from '@solfacil/girassol'
import Steps from './../element/Steps.vue'
import type { StageFormalization } from '~/utils/customer-register/CustomerRegister'
import type { BiometryAlertEnum, BiometryAlertStatus, BiometryResponse, BiometryStatusEnum, UrlBiometry } from '~/utils/customer-register/Biometry'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'
import CustomerBiometryService from '~/services/customer-register/CustomerBiometry'
import IconOpenInNew from '~icons/material-symbols/open-in-new'
import IconDownload from '~icons/material-symbols/download'
import IconLock from '~icons/material-symbols/lock'
import { Formalization } from '~/types/enum'
import { useFinancing } from '~/store/useFinancing'

const props = defineProps<{
  position: number
  statusProject: FormalizationStatus
  stage: StageFormalization
  stageReproved: string
}>()

const emit = defineEmits<{
  (e: 'statusBiometry', v: FormalizationStatus): void
  (e: 'alertBiometry', v: BiometryAlertStatus): void
}>()

const { t } = useI18n()
const { track } = useMixpanel()
const { createErrorToast } = useToast()
const router = useRouter()
const customerBiometryService = new CustomerBiometryService(useApi('customerBiometry'))
const { financing } = useFinancing(router.currentRoute.value.params.id as string)
const globalStatusIsCanceled = financing?.status === 'canceled'
const EnumTags: BiometryStatusEnum = {
  pending: {
    id: 'pending',
    text: t('customer.biometry.status.pending'),
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  pendency: {
    id: 'pendency',
    text: t('customer.biometry.status.pendency'),
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  sent: {
    id: 'sent',
    text: t('customer.biometry.status.sent'),
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  under_analysis: {
    id: 'under_analysis',
    text: t('customer.biometry.status.under_analysis'),
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  reproved: {
    id: 'reproved',
    text: t('customer.biometry.status.reproved'),
    variant: 'fill',
    size: 'medium',
    type: 'negative',
  },
  approved: {
    id: 'approved',
    text: t('customer.biometry.status.approved'),
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
}

const EnumAlerts: BiometryAlertEnum = {
  under_analysis: {
    title: t('customer.biometry.alert.under_analysis.title'),
    text: t('customer.biometry.alert.under_analysis.text'),
    feedback: 'warning',
  },
  pending: {
    title: t('customer.biometry.alert.pending.title'),
    text: t('customer.biometry.alert.pending.text'),
    feedback: 'informative',
  },
}

const loading = ref(true)
const loadingSend = ref(false)
const loadingResend = ref(false)
const loadingCancel = ref(false)

const blocked = computed(() => props.statusProject.registration !== 'approved'
  || props.statusProject.documentation !== 'approved'
  || !['submitted', 'approved'].includes(props.statusProject.receipt_model)
  || !['kit_pending', 'submitted'].includes(props.statusProject.hardware))
const blockedSend = ref(false)
const blockedResend = ref(false)
const blockedCancel = ref(false)

const alert = ref(null) as Ref<string | null>
const resend = ref(false)
const finished = ref(false)
const url = ref({ insurance: null, contract: null } as UrlBiometry)

const id = router.currentRoute.value.params.id as string
const data = ref({} as BiometryResponse)
const status = ref({} as FormalizationStatus)
const tag = computed(() => EnumTags[props.statusProject.contract])
const showModalCancel = ref(false)
const checkCount = ref(0)

watch(
  props.statusProject,
  () => {
    loading.value = true
    getBiometryData()
  },
  { immediate: true },
)

watch(
  blocked,
  () => {
    if (!blocked.value) {
      loading.value = true
      getBiometryData()
    }
  },
)

async function getBiometryData() {
  status.value = props.statusProject
  const registration = status.value.registration === 'approved'
  const documentation = status.value.documentation === 'approved'
  const receipt_model = ['submitted', 'approved'].includes(status.value.receipt_model)
  const hardware = ['kit_pending', 'submitted'].includes(status.value.hardware)
  const contract = ['pending', 'sent', 'under_analysis', 'reproved', 'approved'].includes(status.value.contract)

  if (!registration || !documentation || !receipt_model || !hardware)
    return

  try {
    const resp = await customerBiometryService.get_customer_biometry(id)

    if (!resp)
      return

    data.value = resp
    loading.value = false
    emit('alertBiometry', {
      pendencies: resp.pendencies,
      reproved: status.value.contract === 'reproved',
    } as BiometryAlertStatus)

    if (!contract)
      return

    checkStageReproved()
    const sent = status.value.contract === 'sent'

    if (['sent', 'under_analysis'].includes(status.value.contract)) {
      resend.value = true

      if (sent)
        alert.value = resp.biometry.pending ? 'pending' : null

      else
        alert.value = 'under_analysis'

      if (sent && !resp.resend)
        return blockedResendTimeout(resp.send_biometry_contract.message.time)

      if (sent) {
        blockedResend.value = !resp.resend
        blockedCancel.value = !resp.cancel
        return
      }

      const check_status = status.value.contract === 'under_analysis' && !['under_analysis', 'completed'].includes(resp.contract.status)

      if (check_status && !resp.resend)
        return blockedResendTimeout(resp.send_biometry_contract.message.time)

      if (check_status) {
        blockedResend.value = !resp.resend
        blockedCancel.value = !resp.cancel
      }

      return
    }

    if (status.value.contract === 'reproved') {
      resend.value = true
      blockedSend.value = true
      blockedResend.value = true
      blockedCancel.value = true
      return
    }

    if (status.value.contract === 'approved') {
      url.value = resp.download
      finished.value = true
    }
  }
  catch (error) {
    createErrorToast(t('customer.biometry.catch.data_error'))
    loading.value = false
  }
  finally {
    loading.value = false
  }
}

function blockedResendTimeout(time: number | string) {
  resend.value = true
  blockedResend.value = true
  blockedCancel.value = !data.value.cancel
  setTimeout(() => blockedResend.value = false, Number(time) * 1000 * 60)
}

async function clickSend() {
  track('formalizing_contract_button_send-contract', { trigger: 'Clique no botão de enviar contrato' })
  loadingSend.value = true
  blockedSend.value = true

  try {
    await customerBiometryService.post_customer_biometry(id, data.value)
    checkStatusBiometry()
  }
  catch (error) {
    createErrorToast(t('customer.biometry.catch.send_error'))
    loadingSend.value = false
    blockedSend.value = false
  }
}

async function clickResend() {
  track('formalizing_contract_button_resend-contract', { trigger: 'Clique no botão de reenviar contrato' })
  loadingResend.value = true

  try {
    await customerBiometryService.post_customer_biometry_resend(id)
    blockedResendTimeout(data.value.send_biometry_contract.message.time)
  }
  catch (error) {
    createErrorToast(t('customer.biometry.catch.resend_error'))
    loadingResend.value = false
  }
  finally {
    loadingResend.value = false
  }
}

async function clickCancel() {
  track('formalizing_contract_button_cancel-contract', { trigger: 'Clique no botão de cancelar envio do contrato' })
  showModalCancel.value = false
  loadingCancel.value = true

  try {
    await customerBiometryService.post_customer_biometry_cancel(id)
    resend.value = false
    status.value.contract = 'pending'
    emit('statusBiometry', status.value)
  }
  catch (error) {
    createErrorToast(t('customer.biometry.catch.cancel_error'))
    loadingCancel.value = false
  }
  finally {
    loadingCancel.value = false
  }
}

function clickUrl(url: string) {
  window.open(url, '_blank')
}

async function checkStatusBiometry() {
  const interval = setInterval(async () => {
    checkCount.value++

    if (checkCount.value > 5) {
      clearInterval(interval)
      checkCount.value = 0
      return
    }

    try {
      await getBiometryData()

      if (data.value.biometry.status === 'in_progress') {
        clearInterval(interval)

        loading.value = true
        resend.value = true
        blockedResend.value = true
        status.value.contract = 'sent'
        emit('statusBiometry', status.value)
        blockedResendTimeout(data.value.send_biometry_contract.message.time)

        loading.value = false
        loadingSend.value = false
        blockedSend.value = false
      }
    }
    catch (error) {
      createErrorToast(t('customer.biometry.catch.data_error'))
    }
  }, 5000)
}

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.CONTRACT
}
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.CONTRACT}`"
    :title="t('customer.biometry.title')"
    :position="String(position)"
    :tag="blocked ? undefined : tag"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.CONTRACT"
    class="my-2 lg:system:my-8"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <!-- subtitle -->
    <div class="mt-2">
      <p>{{ t('customer.biometry.subtitle') }}</p>
    </div>

    <SolAlert
      v-if="alert"
      id="alert-biometry"
      :title="EnumAlerts[alert]?.title"
      :text="EnumAlerts[alert]?.text"
      :feedback="EnumAlerts[alert]?.feedback"
      class="mt-7"
    />

    <!-- timeline -->
    <div v-if="!loading">
      <Steps
        :data="data"
        :disabled="globalStatusIsCanceled || blockedSend"
        class="mt-8 -mb-8"
        @method-receipt="(value) => data.send_biometry_contract.method_receipt = value"
      />
    </div>

    <SolDivider thickness="x-small" orientation="horizontal" class="my-7" />

    <!-- footer -->
    <div class="flex flex-col md:system:flex-row flex-col-reverse justify-between" :class="{ 'pointer-events-none opacity-60': globalStatusIsCanceled }">
      <!-- link -->
      <a
        href="https://helpcentersolfacil.zendesk.com/hc/pt-br/articles/15977819037467-Como-cancelo-meu-financiamento"
        target="_blank"
        class="flex items-center gap-2 text-brand-secondary-dark"
      >
        <span>{{ t('customer.biometry.footer.policy') }}</span>
        <IconOpenInNew />
      </a>

      <!-- buttons -->
      <div class="flex flex-col md:system:flex-row  flex-col-reverse justify-end gap-3 mb-7 md:system:mb-0">
        <SolButton
          v-if="resend && !finished"
          id="customer_cancel"
          size="large"
          variant="tertiary"
          class="w-full whitespace-nowrap"
          :loading="loadingCancel"
          :disabled="globalStatusIsCanceled || blockedCancel"
          @click="showModalCancel = true"
        >
          {{ t('customer.biometry.footer.cancel') }}
        </SolButton>

        <SolButton
          v-if="resend && !finished"
          id="customer_resend"
          size="large"
          variant="secondary"
          class="w-full hidden"
          :loading="loadingResend"
          :disabled="globalStatusIsCanceled || blockedResend"
          @click="clickResend"
        >
          {{ t('customer.biometry.footer.resend') }}
        </SolButton>

        <SolButton
          v-if="!resend && !finished"
          id="customer_send"
          size="large"
          class="w-full"
          :loading="loadingSend"
          :disabled="globalStatusIsCanceled || blockedSend"
          @click="clickSend"
        >
          {{ t('app.send') }}
        </SolButton>

        <SolButton
          v-if="finished && url.insurance"
          id="customer_download_insurance"
          size="large"
          variant="secondary"
          class="w-full"
          :disabled="globalStatusIsCanceled"
          @click="clickUrl(url.insurance)"
        >
          <div class="flex items-center gap-3 whitespace-nowrap">
            <IconDownload class="text-sm" />
            <span>{{ t('customer.biometry.footer.insurance') }}</span>
          </div>
        </SolButton>

        <SolButton
          v-if="finished && url.contract"
          id="customer_download_contract"
          size="large"
          class="w-full"
          :disabled="globalStatusIsCanceled"
          @click="clickUrl(url.contract)"
        >
          <div class="flex items-center gap-3 whitespace-nowrap">
            <IconDownload class="text-sm" />
            <span>{{ t('customer.biometry.footer.contract') }}</span>
          </div>
        </SolButton>
      </div>
    </div>
  </CustomerElementAccordion>

  <SolModal
    id="modal"
    :is-open="showModalCancel"
    :title="t('customer.biometry.modal_cancel.title')"
    :action-primary-text="t('customer.biometry.modal_cancel.confirm')"
    :action-secondary-text="t('customer.biometry.modal_cancel.cancel')"
    :size="{ desktop: 'medium', mobile: 'bottom-sheet' }"
    @close="showModalCancel = false"
    @action:primary="clickCancel"
    @action:secondary="showModalCancel = false"
  >
    <div>{{ t('customer.biometry.modal_cancel.description') }}</div>
  </SolModal>
</template>
