<script setup lang="ts">
// Services
import { useToast } from '@solfacil/girassol'
import type { User } from '~/utils/auth/User'
import SimulatorService from '~/services/simulator/Simulator'
import validateCPF from '~/composables/validatorCPF'
import validateCNPJ from '~/composables/validatorCNPJ'

const { t } = useI18n()
const router = useRouter()
const shoppingCartId = ref('')

onMounted(() => {
  shoppingCartId.value = String(router.currentRoute.value.query.shopping_cart_id) !== 'undefined' ? String(router.currentRoute.value.query.shopping_cart_id) : ''
})

const {
  createErrorToast,
} = useToast()

const optionsButtonReport = [
  {
    title: 'Pessoa Física',
    description:
      'Linha de crédito para residências',
    sub_description:
      'Taxas prefixadas em até 120 parcelas, com período de carência de até 6 meses.',
    key: 'pf',
  },
  {
    title: 'Pessoa Jurídica',
    description:
      'Linha de crédito para empresas',
    sub_description:
      'Taxas prefixadas em até 120 parcelas, com período de carência de até 6 meses.',
    key: 'pj',
  },
]

onUpdated(() => {
  smoothScroll()
})

const downPayment = ref(0)
const downPaymentError = ref('')
const projectValue = ref(0)
const projectValueError = ref('')
const document = ref<string | undefined>()
const documentError = ref('')
const representativeDocument = ref<string | undefined>()
const representativeDocumentError = ref('')
const systemPower = ref(0)
const systemPowerError = ref('')

const agreeWithTerms = ref(false)
const agreeWithTermsError = ref('')

const maxFinancedValue = ref()
const minFinancedValue = ref()

const typeReport = ref<string | undefined>()
const typeReportError = ref('')
const typeReportOptions = ref<HTMLDivElement | undefined>()

const { track } = useMixpanel()

function downPaymentNotGtProjectValue(value: number, fromSubmit = false) {
  if (value >= projectValue.value && !fromSubmit)
    return 'Entrada deve ser menor que valor do projeto'

  if (value < 0)
    return 'O valor de entrada não pode ser negativo'

  return ''
}

function projectError(value: number) {
  if (!value)
    return notEmpty(value)

  if (value < 0)
    return 'O valor do projeto não pode ser negativo'

  if ((projectValue.value - downPayment.value) > maxFinancedValue.value)
    return 'Valor do projeto acima do permitido'

  if (minFinancedValue.value && value < minFinancedValue.value)
    return 'Valor do projeto abaixo do permitido'

  return ''
}

function formatDocument(document?: string) {
  if (document)
    return document.replaceAll('.', '').replace('-', '').replace('/', '')

  return null
}

function notEmpty(value: number | string | null | undefined) {
  if (!value)
    return 'Campo obrigatório'

  return ''
}

function systemPowerRules(value: number) {
  if (!value || value === 0)
    return notEmpty(value)
  else if (typeof value === 'number' && (value < 1 || value > 999))
    return 'A potência do sistema deve estar entre 1 kWp e 999 kWp'

  return ''
}

const getDocument = computed(() => {
  return typeReport.value === 'pf' ? document.value : representativeDocument.value
})

const setErrorValue = (message: string) => {
  (typeReport.value === 'pf') ? documentError.value = message : representativeDocumentError.value = message
}

function validateDocument() {
  const cpf = getDocument.value ? getDocument.value : ''
  const isValidCPF = validateCPF(cpf)
  const isValidCNPJ = validateCNPJ(document.value!)

  if (!isValidCPF) {
    setErrorValue('Por favor, digite um CPF válido.')
    return
  }

  if (typeReport.value === 'pj' && !isValidCNPJ) {
    documentError.value = 'Por favor, digite um CNPJ válido.'
    return
  }

  return true
}

watch(downPayment, (value) => {
  downPaymentError.value = downPaymentNotGtProjectValue(value)
  projectValueError.value = projectError(projectValue.value)
})

watch(document, (value) => {
  documentError.value = notEmpty(value)
})

watch(systemPower, (value) => {
  systemPowerError.value = systemPowerRules(value)
})

watch(projectValue, (value) => {
  projectValueError.value = projectError(value)
})

watch(representativeDocument, (value) => {
  representativeDocumentError.value = notEmpty(value)
})

const simulatorService = new SimulatorService(useApi('simulator'))

const loadingMessages = t('loading.messages').split(', ')
const loading = ref(false)
const loadingInputFields = ref(false)

const user: User = useAppStorage().get('user')

function trackEventMixpanel() {
  track('new-simulation_anti-fraud_funnel', { trigger: 'Análise técnica reprovou na simulação ' })
}

async function handleSubmit() {
  projectValueError.value = projectError(projectValue.value)
  if (!document.value || !projectValue.value || !systemPower.value) {
    documentError.value = notEmpty(document.value)
    downPaymentError.value = downPayment.value < 0 ? downPaymentNotGtProjectValue(downPayment.value, true) : ''
    systemPowerError.value = systemPowerRules(systemPower.value)
    if (typeReport.value === 'pj')
      representativeDocumentError.value = notEmpty(representativeDocument.value)

    return
  }

  const errorFields = typeReportError.value || downPaymentError.value || agreeWithTermsError.value || systemPowerError.value || projectValueError.value

  if (errorFields)
    return

  if (!validateDocument())
    return

  loading.value = true

  try {
    const response = await simulatorService.create_project({
      person_type: typeReport.value?.toUpperCase(),
      document: formatDocument(document?.value),
      representative_document: formatDocument(representativeDocument.value),
      project_name: '',
      project_value: projectValue?.value,
      down_payment: downPayment?.value,
      partner_id: user?.parceiro?.id,
      user_id: user?.id,
      system_power: systemPower.value,
      shopping_cart_id: shoppingCartId.value ? shoppingCartId.value : null,
    })

    if (response.status === 201)
      return router.push(`/simulation/result/${response.data?.id}${shoppingCartId.value ? `?shopping_cart_id=${shoppingCartId.value}` : ''}`)

    return router.push('/simulation/new/waiting')
  }
  catch (e: any) {
    if (e?.response?.status === 422) {
      systemPowerError.value = `${t('simulation.system_power_error_422')}`
      trackEventMixpanel()

      return
    }

    createErrorToast(e?.response?._data?.detail || 'Ocorreu um erro ao tentar criar o projeto.')
    return router.push('/simulation/new/waiting')
  }
  finally {
    loading.value = false
  }
}

function selectedTypeReport(type: string) {
  setTypeReport(type)
  clearErrors()
}

async function setTypeReport(type: string) {
  try {
    loadingInputFields.value = true

    typeReport.value = type

    const response = await simulatorService.get_financial_line_limiters(type as 'PF' | 'PJ' | 'PR')
    if (response.data) {
      maxFinancedValue.value = response.data.max_financing_value
      minFinancedValue.value = response.data.min_financing_value
    }
    loadingInputFields.value = false
  }
  catch {
    loadingInputFields.value = false
  }
  finally {
    loadingInputFields.value = false
  }
}

function clearErrors() {
  const errors = [documentError, representativeDocumentError, projectValueError, downPaymentError, systemPowerError]
  errors.map(error => error.value = '')
}

function smoothScroll() {
  if (typeReportOptions.value) {
    const topOffset = typeReportOptions.value.getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({ top: topOffset, behavior: 'smooth' })
  }
}
const isAdmin = computed(() => {
  return !!(user?.perfil?.toLocaleLowerCase() === 'admin')
})

const isIntegradorLight = computed(() => {
  return !!(user?.perfil?.toLocaleLowerCase() === 'integrador_light')
})
</script>

<template>
  <NavigationHeaderBar stage="simulation" />
  <div class="container">
    <form class="grid-row-2 grid gap-5 justify-self-stretch mb-2xs !mt-0 w-full" @submit.prevent="handleSubmit">
      <div v-if="loading" class="my-4 card-container w-full h-96 flex justify-center">
        <Loading :messages="loadingMessages" />
      </div>
      <div v-if="!loading && !isAdmin && !isIntegradorLight" class="mb-4 !mt-0 card-container flex flex-col justify-between w-full">
        <div>
          <span class="fonts-body-large-bold font-bold">
            {{ t('simulation.project_type') }}
          </span>
          <div class="flex flex-col justify-between w-full">
            <span class="text-stroke-feedback-negative-dark">{{ typeReportError }}</span>
            <SimulationMultiSelectButton
              :options="optionsButtonReport"
              :active="typeReport"
              @selected="selectedTypeReport"
            />
            <span v-if="typeReportError" class="error-message">{{ typeReportError }}</span>
          </div>
          <div v-if="loadingInputFields && typeReport" class="w-full h-40">
            <div class="loader-lines" />
          </div>
          <div v-if="typeReport && !loadingInputFields" ref="typeReportOptions" class="pt-xs">
            <span class="title">
              {{ t('simulation.client_data') }}
            </span>
            <p class="sub-title">
              Condições especiais de crédito para o seu cliente em poucos segundos.
            </p>

            <div class="input-options" :class="typeReport === 'pj' ? 'grid-cols-4' : 'grid-cols-3'">
              <SolInputText
                v-if="typeReport === 'pf' || !typeReport"
                id="just-test-2"
                v-model="document"
                :error="documentError"
                :disabled="!typeReport"
                name="document"
                label="CPF do cliente*"
                :mask="{ preset: 'CPF' }"
                placeholder="000.000.000-00"
              />
              <SolInputText
                v-if="typeReport === 'pj'"
                id="just-test-2"
                v-model="document"
                :error="documentError"
                :disabled="!typeReport"
                name="document"
                label="CNPJ do cliente*"
                :mask="{ preset: 'CNPJ' }"
                placeholder="00.000.000/0000-00"
              />
              <SolInputText
                v-if="typeReport === 'pj'"
                id="just-test-2"
                v-model="representativeDocument"
                :error="representativeDocumentError"
                :disabled="!typeReport"
                name="representativeDocument"
                label="CPF do sócio*"
                :mask="{ preset: 'CPF' }"
                placeholder="000.000.000-00"
              />
              <InputMoneyInput
                id="just-test-2"
                v-model="projectValue"
                :disabled="!typeReport"
                size="medium"
                required
                :error="projectValueError"
                name="projectValue"
                label="Valor do projeto"
                placeholder="Digite o valor do projeto*"
              />
              <InputMoneyInput
                id="just-test-2"
                v-model="downPayment"
                :disabled="!typeReport"
                size="medium"
                name="downPayment"
                :error="downPaymentError"
                label="Entrada do cliente para você"
                placeholder="Digite o valor da entrada*"
              />
              <InputSystemPower
                id="system-power"
                v-model="systemPower"
                :disabled="!typeReport"
                size="medium"
                name="systemPower"
                :error="systemPowerError"
                label="Potência do sistema*"
                placeholder="Digite o valorda potência*"
              />
            </div>
            <SolCheckbox
              id="is-admin"
              name="admin"
              value="yes"
              :checked="agreeWithTerms"
              @change="agreeWithTerms = !agreeWithTerms"
            >
              <span>{{ t('simulation.cpf_use_terms.client') }}</span>
              <a
                href="https://helpcentersolfacil.zendesk.com/hc/pt-br/articles/26540116637211-TERMOS-E-CONDI%C3%87%C3%95ES-PLATAFORMA-SOLF%C3%81CIL-TERMO"
                target="_blank"
                class="ml-1 text-brand-secondary-pure hover:text-brand-secondary-dark"
              >
                {{ t('simulation.cpf_use_terms.terms') }}
              </a>
              <span>{{ t('simulation.cpf_use_terms.with') }}</span>
              <a
                href="https://landing.solfacil.com.br/scr/"
                target="_blank"
                class="mx-1 text-brand-secondary-pure hover:text-brand-secondary-dark"
              >
                {{ t('simulation.cpf_use_terms.terms_authorization') }}
              </a>
              <span>{{ t('simulation.cpf_use_terms.and_with') }}</span>
              <a
                href="https://landing.solfacil.com.br/politica-de-privacidade/?_gl=1*a56jbv*_ga*MTg5MDEwMTU0OS4xNjk3MDc2Mzkx*_ga_NQJ9ZNTL71*MTY5NzIwNzM2NC4zLjEuMTY5NzIwNzM3My41MS4wLjA./"
                target="_blank"
                class="mx-1 text-brand-secondary-pure hover:text-brand-secondary-dark"
              >
                {{ t('simulation.cpf_use_terms.privacy_policy') }}
              </a>
              <span>{{ t('simulation.cpf_use_terms.solfacil') }}</span>
            </SolCheckbox>
            <span class="error-message">{{ agreeWithTermsError }}</span>
          </div>
        </div>
      </div>
      <div v-if="!loading && (isAdmin || isIntegradorLight)" class="my-4 card-container flex flex-col justify-between w-full items-center">
        <SolEmptyState
          v-if="isAdmin"
          id="empty-adm-solfacil"
          title="Administrador Solfácil"
          subtitle="Devido à política de precificação, somente integradores podem iniciar uma nova simulação de financiamento."
          variant="no-content"
        />
        <SolEmptyState
          v-if="isIntegradorLight"
          id="empty-adm-solfacil"
          title="Integrador Light"
          subtitle="Devido à política de precificação, você ainda não tem acesso às linhas de financiamento. Entre em contato com o atendimento para verificar os requisitos para acessar o financiamento."
          variant="no-content"
        />
      </div>
      <div v-if="!isAdmin && !isIntegradorLight && !loading" class="submit-container">
        <SolButton
          id="go"
          class="self-end"
          type="submit"
          :disabled="!agreeWithTerms || loading"
          size="large"
        >
          {{ t('app.next') }}
        </SolButton>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.container {
  @apply flex flex-col items-start px-4xs pb-6 mt-6;
  @screen md:system {
    @apply px-12 py-12 mt-0;
  }

  .loader-lines {
    @apply h-40 rounded-lg mt-5;
    @apply top-0 right-0 bottom-0 left-0 z-50;
    background: rgba(#fff, 0.9)
      url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
      center center;
  }

  > form {
    @apply my-0;
    @screen md:system {
     @apply my-2xs;
    }
  }

  .title {
    @apply flex justify-between;
    @apply fonts-body-large-bold;
    @apply text-neutral-low-dark;
    @apply pb-1;
  }

  .sub-title {
    @apply fonts-subtitle-small text-neutral-low-light;
  }
}

.submit-container {
  @apply border-t-neutral-low-light w-full flex justify-end;
  @screen xsm:system {
    @apply justify-center;
  }
  > button {
    @screen xsm:system {
      @apply w-full;
    }
  }
}

.input-options {
  @apply my-2xs grid gap-4;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  @screen lg:site {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
}

.error-message{
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.card-container{
  @apply m-0 px-4xs py-6;
  background-color: white;
  background-color: white;
  border-radius: 16px;
  @screen md:system {
    @apply p-12 my-6;
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
    </route>
