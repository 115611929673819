import { useMutation } from '@tanstack/vue-query'

import queryClient, { queryKeys } from '../../queryClient'
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '~/constants'
import storage from '~/foundation/storage/localstorage'
import keycloackApi from '~/services-v2/keycloack-api/keycloack-api'
import cookie from '~/foundation/storage/cookie'

export default function useLogout() {
  const mutationKey = queryKeys.AUTH.LOGOUT

  async function mutationFn() {
    const refreshToken = storage.get<string>(REFRESH_TOKEN_STORAGE_KEY)
    if (!refreshToken)
      throw new Error('Refresh token not found')
    return keycloackApi.logout(refreshToken)
  }

  return useMutation({
    mutationFn,
    mutationKey,
    onSuccess() {
      storage.remove(ACCESS_TOKEN_STORAGE_KEY)
      storage.remove(REFRESH_TOKEN_STORAGE_KEY)
      cookie.remove('access_token')
      cookie.remove('refresh_token')
      window.location.href = '/'
      queryClient.refetchQueries({ queryKey: queryKeys.AUTH.STATUS })
    },
    retry: 5,
    retryDelay: 500,
  })
}
