import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'

import { BASE_URL as KEYCLOACK_BASE_URL } from './keycloack-api.contants'
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from '~/constants'
import env from '~/env'
import storage from '~/foundation/storage/localstorage'

/**
 * Interceptor to inject the access token into the request.
 *
 * @param config - The request config.
 * @returns The new request config.
 */
export const injectAccessTokenInterceptor = (config: AxiosRequestConfig) => {
  const accessToken = storage.get<string>(ACCESS_TOKEN_STORAGE_KEY)
  if (accessToken && config.headers)
    config.headers.Authorization = `Bearer ${accessToken}`

  return config
}

/**
 * Interceptor to handle expired tokens.
 *
 * @param error - The error.
 * @returns The new request.
 */
export const refreshTokenResponseInterceptor = async (error: AxiosError) => {
  const originalRequest = error.config as { _retry?: boolean } & AxiosRequestConfig
  if (!originalRequest)
    return Promise.reject(error)

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    try {
      // Tenta renovar o token
      const refreshToken = storage.get<string>(REFRESH_TOKEN_STORAGE_KEY)
      if (!refreshToken)
        return Promise.reject(error)

      const res = await axios.post(`${KEYCLOACK_BASE_URL}/token`, new URLSearchParams([
        ['client_id', String(env.VITE_KEYCLOAK_CLIENT_ID)],
        ['grant_type', 'refresh_token'],
        ['refresh_token', refreshToken],
        ['redirect_uri', `${window.location.origin}/${window.location.pathname}`],
      ]))

      // update tokens
      storage.set(ACCESS_TOKEN_STORAGE_KEY, res.data.access_token)
      storage.set(REFRESH_TOKEN_STORAGE_KEY, res.data.refresh_token)

      // update original request header with new token
      if (originalRequest.headers)
        originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`

      return axios(originalRequest)
    }
    catch (refreshError) {
      // if refresh token is invalid then remove tokens and throw error
      storage.remove(ACCESS_TOKEN_STORAGE_KEY)
      storage.remove(REFRESH_TOKEN_STORAGE_KEY)
      return Promise.reject(refreshError)
    }
  }

  return Promise.reject(error)
}
