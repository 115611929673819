import type { PersonService } from './person-api.types'
import date from '~/utils/date'

export function getCurrentScore(user: PersonService.User) {
  if (!user.partner)
    return '-'

  if (user.partner?.sfplus?.is_level_black && user.partner.sfplus.black_status)
    return user.partner.sfplus.black_status.kwReachedMonth

  return user.partner?.sfplus?.score?.current || '-'
}

export function getGoalScore(user: PersonService.User) {
  if (!user.partner || !user?.partner?.sfplus)
    return '-'

  if (user?.partner?.sfplus?.is_level_black && user.partner.sfplus.black_status)
    return user.partner.sfplus.black_status.kwGoalMonth

  return user.partner.sfplus.score?.goal || '-'
}

export function getHasRisk(user: PersonService.User) {
  return user.partner?.sfplus?.downgrade?.has_risk || false
}

export function getNextTrasitionDate(user: PersonService.User) {
  if (!user.partner)
    return '-'

  if (user?.partner?.sfplus?.next_transition_date === null || !user.partner?.sfplus?.next_transition_date)
    return '-'

  return date(user.partner.sfplus.next_transition_date).format('DD/MM/YYYY') || null
}

export function getLevel(user: PersonService.User) {
  return user.partner?.sfplus?.level || '-'
}
