<script setup lang="ts">
import type { User } from '~/utils/auth/User'
import { Journey } from '~/types/enum'
const router = useRouter()
const user: User = useAppStorage().get('user')

const fallbackRouter = async () => {
  const flagNewJourney = await useFlag('new_journey', { partner_id: user?.parceiro?.id })
  const path = flagNewJourney === Journey.OLD ? '/simulation/list' : '/financing/list'

  router.push(path)
}

onMounted(() => {
  fallbackRouter()
})
</script>

<template>
  <div>
    <h1>Financiamento Solfacil</h1>
  </div>
</template>
