<script setup lang="ts">
import { useToast } from '@solfacil/girassol'
import RegisterService from '~/services/customer-register/CustomerRegister'

defineProps({
  statusStep: {
    type: String,
    required: true,
  },
})

const { track } = useMixpanel()

const { t } = useI18n()
const router = useRouter()
const registerService = new RegisterService(useApi('customerRegister'))
const toggleModalCancel = ref(false)
const toast = useToast()

const cancelAnalysis = async () => {
  track('customer_data_modal_confirm-cancel', { trigger: 'Clique no botão no mocal de cancelar análise' })

  try {
    await registerService.cancel_analysis(String(router.currentRoute.value.params.id))

    toast.createSuccessToast({
      title: '',
      description: t('form.cancelAnalysis.successToast'),
    })
  }
  catch (error) {
    toast.createErrorToast({
      description: t('form.cancelAnalysis.errorToastDescription'),
    })
  }
  finally {
    toggleModalCancel.value = false
  }
}

const openModalCancel = () => {
  track('customer_data_button_cancel-analysis', { trigger: 'Clique no botão cancelar análise' })
  toggleModalCancel.value = true
}
</script>

<template>
  <SolButton
    id="cancel_analysis"
    class="w-full md:system:w-auto order-2 md:system:order-1"
    variant="secondary"
    size="large"
    :disabled="statusStep !== 'under_analysis'"
    @click="openModalCancel"
  >
    {{ t('app.cancel') }}
  </SolButton>

  <SolModal
    id="modal"
    :is-open="toggleModalCancel"
    :title="t('form.cancelAnalysis.title')"
    :action-primary-text="t('form.cancelAnalysis.confirm')"
    :action-secondary-text="t('form.cancelAnalysis.back')"
    :size="{
      desktop: 'small',
      mobile: 'bottom-sheet',
    }"
    @close="toggleModalCancel = false"
    @action:primary="cancelAnalysis"
    @action:secondary="toggleModalCancel = false"
  >
    <div>
      {{ t('form.cancelAnalysis.message') }}
    </div>
  </SolModal>
</template>
