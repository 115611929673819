<script setup lang="ts">
import { SolSteps, SolTag } from '@solfacil/girassol'
import type { FinancingFlowResponse, FormalizationStatus } from '~/utils/financing-flow/Financing'
import type { StageFormalization, Step, Tag } from '~/utils/customer-register/CustomerRegister'
import type { User } from '~/utils/auth/User'
import verify from '~/utils/verify'

const props = defineProps<{
  stage: 'simulation' | 'formalization' | 'installation' | 'bnpl'
  title?: string
  status?: string
  projectStatus?: FinancingFlowResponse
  bnplStatus?: string
}>()

const emit = defineEmits<{
  (e: 'stage', v: StageFormalization)
}>()

const { t } = useI18n()
const router = useRouter()
const routeName = router.currentRoute.value.name as string
const user: User = useAppStorage().get('user')
const id = router.currentRoute.value.params.id as string
const financing = ref({}) as Ref<FinancingFlowResponse>

const stepsFormalization = [
  {
    key: 'simulation',
    text: t('header.project.steps.simulation'),
  },
  {
    key: 'formalization',
    text: t('header.project.steps.formalization'),
    current: false,
    failed: false,
  },
  {
    key: 'installation',
    text: t('header.project.steps.installation'),
    current: false,
  },
]

let tag = {
  text: '',
  type: 'neutral',
} as Tag

const stepsBNPL = [
  {
    key: 'simulation',
    text: t('header.bnpl.steps.simulation'),
    current: isCurrentPage('simulation'),
    perfil: user.perfil,
  },
  {
    key: 'formalization',
    text: t('header.bnpl.steps.formalization'),
    subtitle: t('header.bnpl.steps.external'),
    current: isCurrentPage('formalization'),
  },
]

const stepsSimulation = [
  {
    key: 'new',
    text: t('customer.header.steps.simulation'),
    current: true,
    perfil: user.perfil,
  },
  {
    key: 'result',
    text: t('header.bnpl.steps.formalization'),
    current: false,
  },
  {
    key: 'details',
    text: t('customer.header.steps.installation'),
    current: false,
  },
]

const stepsInstallation = [
  {
    key: 'new',
    text: t('customer.header.steps.simulation'),
    current: false,
  },
  {
    key: 'result',
    text: t('customer.header.steps.formalization'),
    current: false,
  },
  {
    key: 'details',
    text: t('customer.header.steps.installation'),
    current: props.status !== 'success',
  },
]

const stageConfig = {
  bnpl: {
    title: props.title ? simpleName(props.title) : t('simulation.new'),
    steps: stepsBNPL,
  },
  simulation: {
    title: props.title ? simpleName(props.title) : t('simulation.new'),
    steps: stepsSimulation,
  },
  formalization: {
    title: props.title ? simpleName(props.title) : t('customer.new'),
    steps: stepsFormalization,
  },
  installation: {
    title: props.title ? simpleName(props.title) : t('installationPage.messageNewInstallation'),
    steps: stepsInstallation,
  },
}

const isAdmin = computed(() => {
  return Boolean(stepsSimulation.find(step => step?.text === t('header.project.steps.new') && step?.perfil?.toLocaleLowerCase() === 'admin'))
})

const isIntegradorLight = computed(() => {
  return Boolean(stepsSimulation.find(step => step?.text === t('header.project.steps.new') && step?.perfil?.toLocaleLowerCase() === 'integrador_light'))
})

watch(() => props.status, () => {
  setStatusInstallation()
})

watch(() => props.bnplStatus, () => {
  setBnplStatus()
})

watch(
  props.projectStatus as FinancingFlowResponse,
  () => {
    if (props.projectStatus)
      setStatusFormalization()
  },
  { immediate: true },
)

onBeforeMount(async () => {
  await getFinancing()

  if (props.stage === 'formalization')
    setStatusFormalization()

  if (props.stage === 'installation')
    setStatusInstallation()

  if (props.stage === 'bnpl')
    setBnplStatus()
})

function getTitle() {
  const config = stageConfig[props.stage]
  return config ? config.title : ''
}

watch(() => props.title, () => {
  const config = stageConfig[props.stage]
  config.title = props.title ? simpleName(props.title) : ''
})

function setTag() {
  const info = {
    text: '',
    type: 'neutral',
  } as Tag

  if (props.stage === 'simulation' && isIntegradorLight.value) {
    info.text = t('header.project.int_light')
    info.type = 'positive'
  }
  else if (props.stage === 'simulation' && isAdmin.value) {
    info.text = t('header.project.admin')
    info.type = 'positive'
  }
  else if (tag.text && ['formalization', 'installation', 'bnpl'].includes(props.stage)) {
    info.text = tag.text
    info.type = tag.type
  }

  return info
}

function getStep() {
  const config = stageConfig[props.stage]
  return config ? config.steps : []
}

function setStatusFormalization() {
  const step = getStepByKey('formalization')
  step.current = true
  const formalization = props.projectStatus?.section_statuses?.formalization
  const status = props.projectStatus?.status

  if (status === 'canceled') {
    const markStepAsFailed = (key) => {
      const step = getStepByKey(key)
      step.failed = true
      step.current = false
    }

    markStepAsFailed(step.key)

    step.key === 'formalization' ? markStepAsFailed('installation') : markStepAsFailed('formalization')

    tag.text = t('customer.header.tags.canceled')
    tag.type = 'negative'
    return
  }

  if (formalization) {
    emitStage(formalization)
    const { registration, documentation, hardware, receipt_model, contract } = formalization as FormalizationStatus
    const isApproved = registration === 'approved'
                     && documentation === 'approved'
                     && (receipt_model === 'submitted' || receipt_model === 'approved')
                     && hardware === 'submitted'
                     && contract === 'approved'

    if ([registration, documentation, contract].includes('reproved')) {
      step.failed = true
      tag.text = t('customer.header.tags.reproved')
      tag.type = 'negative'

      removeActiveClassName()
    }
    else if (isApproved) {
      step.current = false
      const installation = getStepByKey('installation')
      installation.current = true
      tag.text = t('customer.header.tags.approved')
      tag.type = 'positive'
    }
    else if ([registration, documentation, contract].includes('pendency')) {
      tag.text = t('customer.header.tags.pendency')
      tag.type = 'warning'
    }
    else if ([registration, documentation, hardware, receipt_model].includes('pending')) {
      tag.text = t('customer.header.tags.pending')
      tag.type = 'warning'
    }
    else if ([registration, documentation, contract].includes('under_analysis')) {
      tag.text = t('customer.header.tags.under_analysis')
      tag.type = 'informative'
    }
    else if (['submitted', 'approved'].includes(receipt_model)) {
      if (contract === 'pending') {
        tag.text = t('customer.header.tags.send_contract')
        tag.type = 'warning'
      }
      else if (contract === 'sent') {
        tag.text = t('customer.header.tags.sent')
        tag.type = 'informative'
      }
      else if (hardware === 'kit_pending') {
        tag.text = t('customer.header.tags.kit_pending')
        tag.type = 'warning'
      }
    }
  }
}

function setStatusInstallation() {
  if (props.status) {
    const TYPE_TAG = {
      [props.status]: {
        type: t(`installationPage.barInstallation.${props.status}.type`),
        text: t(`installationPage.barInstallation.${props.status}.text`),
      } as Tag,
    }

    tag = TYPE_TAG[props.status]
  }
}

function setBnplStatus() {
  if (props.bnplStatus) {
    const TYPE_TAG = {
      [props.bnplStatus]: {
        type: t(`header.bnpl.status.${props.bnplStatus}.type`),
        text: t(`header.bnpl.status.${props.bnplStatus}.text`),
      } as Tag,
    }
    tag = TYPE_TAG[props.bnplStatus]
  }
}

function simpleName(name: string) {
  const ignoredWords = ['de', 'da', 'do', 'dos', 'das', 'e', 'ou', 'para', 'com']
  const words = name.toLowerCase().split(' ')
  const formattedWords = words.map((word, index) => {
    if (index === 0 || index === words.length - 1)
      return word.charAt(0).toUpperCase() + word.slice(1)

    return ignoredWords.includes(word) ? null : `${word.charAt(0).toUpperCase()}.`
  }).filter(Boolean)

  return formattedWords.join(' ')
}

function isCurrentPage(path: string) {
  return router.currentRoute.value.fullPath.includes(path)
}

function getStepByKey(value: string) {
  return stepsFormalization.find(step => step.key === value) as Step || []
}

function removeActiveClassName() {
  setTimeout(() => {
    const active = document.querySelector('.-active')
    if (active)
      active.classList.remove('-active')
  })
}

function emitStage(data: FormalizationStatus) {
  const stagesToCheck = [
    ['reproved', 'registration'],
    ['reproved', 'documentation'],
    ['reproved', 'contract'],
    ['pendency', 'registration'],
    ['pendency', 'documentation'],
    ['pendency', 'contract'],
    ['pending', 'registration'],
    ['pending', 'documentation'],
    ['pending', 'hardware'],
    ['pending', 'receipt_model'],
    ['under_analysis', 'registration'],
    ['under_analysis', 'documentation'],
    ['pending', 'contract'],
    ['sent', 'contract'],
    ['under_analysis', 'contract'],
    ['kit_pending', 'hardware'],
  ]

  for (const [status, stage] of stagesToCheck) {
    if (data[stage] === status)
      return emit('stage', stage as StageFormalization)
  }
}

function notIsRoute() {
  return !['simulation-new', 'simulation-result-id'].includes(routeName)
}

async function getFinancing() {
  if (routeName !== 'simulation-new')
    financing.value = await verify.financingFlow(id)
}
</script>

<template>
  <header class="header">
    <div :class="{ 'my-3': !notIsRoute() && !financing?.id }">
      <div class="title-simulation-bar" :title="getTitle()">
        <div>{{ getTitle() }}</div>

        <SolTag
          v-if="setTag().text"
          id="tag-header-bar"
          :text="setTag().text"
          :type="setTag().type"
          size="medium"
          class="mt-1"
        />
      </div>
      <span v-if="financing?.id" class="text-neutral-low-light text-2xs">
        <span v-if="stage === 'bnpl'">{{ t('simulation.bnpl.solfacilInstallment') }}</span>
        <span v-else>{{ t('header.project.title') }}</span>
        <span class="mx-1">|</span>
        <span>{{ t('simulation.bnpl.identity') }}: {{ financing.id }}</span>
      </span>
    </div>

    <div v-if="notIsRoute()" class="steps-container">
      <SolSteps id="package" :steps="getStep()" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  @apply w-full flex flex-wrap lg:system:flex-nowrap items-center justify-between shadow-weak bg-neutral-high-pure py-4 px-4 md:system:px-12 z-10;

  .title-simulation-bar {
    @apply w-full fonts-heading-h2 flex items-center justify-between md:system:justify-start gap-3 truncate my-1;
  }

  .steps-container {
    @apply w-full py-2 my-1;

    @screen sx:system {
      @apply w-1/3 py-0;
    }

    @screen lg:system {
      @apply w-100 relative w-[400px];
      right: -35px;
    }
  }
}
</style>
